'use strict';

const ProgramSites = require('./programSites'),
    ConsumerSite = require('../models/consumerSite');

module.exports = ProgramSites.extend({
    //
    model: ConsumerSite,
    //
    url: function () {
        return '/consumers/program/' + this.programId;
    }
});
