'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    //
    Table = require('../../table/table'),
    //
    PasswordRecovery = require('../../../models/passwordRecovery'),
    //
    templates = require('../../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'users-list users-list-sort-user sort-up card-list',
        'data-name': 'users-list'
    },
    ui: {
        reset: '[data-action="reset"]',
        sort: '[data-action="sort"]'
    },
    events: {
        'click @ui.reset': 'onResetRequest',
        'click @ui.sort': 'onSort'
    },
    regions: {
        usersTable: {el: 'div[data-region=users-table]', replaceElement: true}
    },
    tagName: 'div',
    template: templates.usersList,
    //
    initialize: function (options) {
        this.collection = options.collection;
        this.rowTemplate = options.rowTemplate;
        this.collection.fetch();
    },
    onRender: function () {
        this.users = this.showChildView('usersTable', new Table({
            collection: this.collection,
            rowTemplate: this.rowTemplate
        }));
    },
    onResetRequest: function (event) {
        const action = event.currentTarget.dataset.action,
            value = event.currentTarget.dataset[action];

        const $button = this.$(event.currentTarget);
        if (!$button.hasClass('disabled')) {
            $button.addClass('disabled');

            const originalText = $button.text();
            $button.text('Request Sent');

            setTimeout(() => {
                $button.text(originalText);
                $button.removeClass('disabled');
            }, 1500);

            const passwordRecovery = new PasswordRecovery({ email: value });
            passwordRecovery.save();
        }

        return false;
    },
    onSort: function (event) {
        const action = event.currentTarget.dataset.action,
            value = event.currentTarget.dataset[action];

        const sortColumns = this.collection.getSortColumns();
        let sortDirection = this.collection.getSortDirection();
        let sortClass = this.getSortClass(sortColumns, sortDirection);
        this.$el.removeClass(sortClass);

        if (sortColumns === value) {
            // Toggle the sort direction.
            sortDirection = (sortDirection === 'ASC') ? 'DESC' : 'ASC';
        } else {
            sortDirection = 'DESC';
        }

        sortClass = this.getSortClass(value, sortDirection);
        this.$el.addClass(sortClass);

        this.collection.setSortDirection(sortDirection);
        this.collection.setSortColumns(value);
        this.trigger('refresh.users');

        return false;
    },
    getSortClass: function (sortColumns, sortDirection) {
        const sortClass = this.$(`[data-sort="${sortColumns}"]`).data('sort-class');
        const sortUpDown = (sortDirection === 'ASC') ? 'sort-up' : 'sort-down';

        return `${sortClass} ${sortUpDown}`;
    }
});
