'use strict';

const Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    Programs = require('../../collections/programs'),
    DashboardSites = require('../../collections/dashboardSites'),
    DashboardConsumerSites = require('../../collections/dashboardConsumerSites'),
    //
    DeveloperStatusDashboard = require('./developerStatusDashboard/developerStatusDashboard'),
    SiteStatusDashboard = require('./siteStatusDashboard/siteStatusDashboard'),
    //
    templates = require('../../utilities/handlebars').templates;

const ProgramCount = Backbone.Model.extend({
    urlRoot: '/programs/count'
});

module.exports = Marionette.View.extend({
    attributes: {
        class: 'dashboard',
        'data-name': 'dashboard'
    },
    ui: {
        liveProgramsCount: '.js-live-programs-count',
        updateProgramsCount: '.js-update-programs-count',
        newProgramsCount: '.js-new-programs-count'
    },
    regions: {
        developerTimelines: {el: 'div[data-region=developer-timelines]', replaceElement: true},
        consumerTimelines: {el: 'div[data-region=consumer-timelines]', replaceElement: true},
        siteTimelines: {el: 'div[data-region=site-timelines]', replaceElement: true}
    },
    tagName: 'div',
    template: templates.dashboard,
    initialize: function () {
        this.collections = {
            programs: new Programs(),
            consumers: new DashboardConsumerSites(),
            sites: new DashboardSites()
        };
        this.collections.consumers.setCount(Number.MAX_SAFE_INTEGER);
        this.collections.sites.setCount(Number.MAX_SAFE_INTEGER);
        this.collections.programs.setCount(Number.MAX_SAFE_INTEGER);
        this.collections.programs.setForDashboard(true);
        this.collections.programs.setSortColumns('developerOrganizationName');

        const programCount = new ProgramCount();
        this.programCount = programCount.fetch();

        this.listenTo(this.collections.consumers, 'sync', () => {
            this.$el.toggleClass('has-consumers', !!this.collections.consumers.length);
            this.updateProgramCounts();
        });

        this.listenTo(this.collections.sites, 'sync', () => {
            this.$el.toggleClass('has-sites', !!this.collections.sites.length);
            this.updateProgramCounts();
        });

        this.listenTo(this.collections.programs, 'sync', () => {
            this.$el.toggleClass('has-programs', !!this.collections.programs.length);
            this.updateProgramCounts();
        });
    },
    updateProgramCounts: function () {
        const updatePrograms = this.collections.programs.filter((model) => !!model.get('annualUpdate')).length;
        const newPrograms = this.collections.programs.length - updatePrograms;

        const updateSites = this.collections.sites.filter((model) => !!model.get('annualUpdate')).length;
        const newSites = this.collections.sites.length - updateSites;

        const updateCount = updatePrograms + updateSites;
        const newCount = newPrograms + newSites;

        this.ui.updateProgramsCount.text(updateCount);
        this.ui.newProgramsCount.text(newCount);

    },
    onRender: function () {
        this.programCount.then((model) => this.ui.liveProgramsCount.text(model.count));

        this.showChildView('developerTimelines', new DeveloperStatusDashboard({ collection: this.collections.programs }));
        this.showChildView('consumerTimelines', new SiteStatusDashboard({ collection: this.collections.consumers, templateName: 'consumerStatusDashboard' }));
        this.showChildView('siteTimelines', new SiteStatusDashboard({ collection: this.collections.sites, templateName: 'siteStatusDashboard' }));
    }
});
