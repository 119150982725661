'use strict';

const Backbone = require('backbone'),
    Answer = require('../models/answer');

const Answers = Backbone.Collection.extend({
    model: Answer,
    surveyType: null,
    organizationId: 0,
    programId: 0,
    url: function () {
        return `/answers/${this.surveyType}/${this.organizationId}/${this.programId}`;
    },
    getSurveyType: function () {
        return this.surveyType;
    },
    setSurveyType: function (surveyType) {
        this.surveyType = surveyType;
    },
    getOrganizationId: function () {
        return this.organizationId;
    },
    setOrganizationId: function (organizationId) {
        this.organizationId = organizationId;
    },
    getProgramId: function () {
        return this.programId;
    },
    setProgramId: function (programId) {
        this.programId = programId;
    },
    save: function (options) {
        return Backbone.sync('update', this, options);
    }
});

module.exports = Answers;