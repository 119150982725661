'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    //
    templates = require('../../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'content-header programs-header sticky-top view-header',
        'data-name': 'programs-header'
    },
    ui: {
        form: 'form',
        filter: '.js-filter',
        search: '.js-search',
        select: 'select[name="programStatusesLookup"]'
    },
    events: {
        'change @ui.select': 'onSelect',
        'click @ui.search': 'onSubmit',
        'submit': 'onSubmit'
    },
    tagName: 'div',
    template: templates.programsHeader,
    //
    initialize: function (options) {
        this.collection = options.collection;
    },
    onSelect: function () {
        const value = this.ui.select.val();
        this.collection.setProgramStatusId(value);
        this.trigger('refresh.programs');

        return false;
    },
    onSubmit: function () {
        const filter = this.ui.filter;
        this.collection.setMatchingText(filter.val());
        this.trigger('refresh.programs');
        
        return false;
    }
});
