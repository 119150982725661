'use strict';

const Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    _ = require('lodash'),
    //
    Answer = require('../../../models/siteAnswer'),
    SiteAnswers = require('../../../collections/siteAnswers'),
    Questions = require('../../../collections/questions'),
    //
    utils = require('../../../utilities/utils'),
    templates = require('../../../utilities/handlebars').templates;

const Study = Backbone.Model.extend({
    urlRoot: function () {
        return `/study/${this.programId}`;
    },
    idAttribute: 'programDataSetId',
    setSurveyType: function (surveyType) {
        this.surveyType = surveyType;
    },
    getProgramId: function () {
        return this.programId;
    },
    setProgramId: function (programId) {
        this.programId = programId;
    }
});

const Article = Backbone.Model.extend({
    urlRoot: function () {
        return `/study/article/${this.programId}`;
    },
    idAttribute: 'programDataSetId',
    setSurveyType: function (surveyType) {
        this.surveyType = surveyType;
    },
    getProgramId: function () {
        return this.programId;
    },
    setProgramId: function (programId) {
        this.programId = programId;
    }
});

module.exports = Marionette.View.extend({
    attributes: {
        class: 'research-tab',
        'data-name': 'research-tab'
    },
    ui: {
        addStudy: '.js-add-study',
        addArticle: '.js-add-article',
        retireArticle: '.js-retire-article',
        researchWrapper: '.js-research-wrapper',
        saveAndNext: '.js-save-and-next'
    },
    events: {
        'click @ui.addStudy': 'onAddStudy',
        'click @ui.addArticle': 'onAddArticle',
        'click @ui.retireArticle': 'onRetireArticle',
        'click @ui.saveAndNext': 'onSaveAndNext'
    },
    tagName: 'div',
    template: templates.researchTab,
    //
    initialize: function (options) {
        this.questions = options.programDetails.get('questions');
        this.answers = options.programDetails.get('answers');
        this.programDetails = options.programDetails;
        this.programId = options.programId;
        this.organizationId = options.organizationId;

        this.collection = new Backbone.Collection();

        this.computeAnswers();
    },
    computeAnswers: function () {
        const allQuestions = utils.getResearchCategoryQuestions(this.questions, this.answers);

        const studyIdQuestion = allQuestions.q_qstudy.filter((qt) => qt.tag === 'studyid')[0];
        const studyIdAnswers = studyIdQuestion ? allQuestions.q_answers.filter((a) => a.questionId === studyIdQuestion.id) : [];
        const programDataSets = studyIdAnswers.map((a) => {
            return {
                programDataSetId: a.programDataSetId,
                studyId: parseInt(a.responseValue, 10)
            };
        }).sort((a,b) => +a.studyId - b.studyId);

        programDataSets.forEach((pds) => {
            this.cloneAnswers(allQuestions, pds);
            const studyNameQuestion = allQuestions.q_qstudy.filter((qName) => qName.tag === 'studyName')[0];
            const studyNameAnswers = studyNameQuestion ? allQuestions.q_answers.filter((a) => a.questionId === studyNameQuestion.id) : [];
            const studyNameAnswer = studyNameAnswers.filter((a) => a.programDataSetId === pds.programDataSetId)[0];
            pds.studyName = (studyNameAnswer) ? studyNameAnswer.responseValue : '';
        });
        this.collection.reset(programDataSets);
    },
    cloneAnswers: function (allQuestions, pds) {
        const pdsAnswers = allQuestions.q_answers.filter((a) => a.programDataSetId === pds.programDataSetId);
        const pdsQuestions = _.cloneDeep(allQuestions.q_questions);

        pdsQuestions.forEach((q) => {
            q.programDataSetId = pds.programDataSetId;
            q.responses.forEach((r) => {
                r.answers = _.filter(pdsAnswers, {questionId: q.id, responseId: r.id});
            });
        });

        const q = utils.getResearchCategoryQuestions(pdsQuestions, pdsAnswers);

        Object.keys(q).forEach((f) => {
            pds[f] = _.cloneDeep(q[f]);
        });

        const referenceProperties = {
            level1Value: 'Research Evidence',
            level2Value: 'Study Findings',
            level3Value: 'References',
            tag: 'articleReference'
        };

        const articlesProperties = {
                level1Value:'Research Evidence', level2Value:'Study Findings'
            },
            // articleReferenceParts = ['authors','title','journal','url'],
            questions = _.filter(this.questions, articlesProperties),
            questionsLookup = _.keyBy(questions, 'id'),
            questionAnswers = _.filter(this.answers, function (answer) {
                return !!questionsLookup[answer.questionId];
            }),
            pdsIdsForStudy = _.map(_.filter(questionAnswers, {responseCode: 'studyid', responseValue: String(pds.studyId) }), 'programDataSetId');

        // Each study can have zero or more articles. Each article is essentially a list of questions.
        pds.articles = [];

        pdsIdsForStudy.forEach((pdsId) => {
            const articleOutcomeQuestions = _.cloneDeep(questions);
            articleOutcomeQuestions.forEach((aroQuestion) => {
                aroQuestion.programDataSetId = pdsId;
                aroQuestion.responses.forEach((aroResponse) => {
                    aroResponse.answers = _.filter(this.answers, { programDataSetId: pdsId, responseId: aroResponse.id });
                });
            });
            const l4Questions = _.groupBy(articleOutcomeQuestions, (q4) => q4.level4Value);
            const studyFindings = [];
            _.each(l4Questions, (l4Question, l4name) => {
                const l5Questions = _.groupBy(l4Question, (l5Question) => l5Question.level5Value);
                const L5subsections = [];
                _.each(l5Questions, (l5QuestionList, l5name) => L5subsections.push({ name: l5name, questions: l5QuestionList }));
                if (l4name) {
                    studyFindings.push({ name: l4name, L5subsections });
                }
            });
            // Find all the "article" questions.
            const articleQuestions = [];
            // Put the "reference" question at the beginning of the list.
            articleQuestions.unshift(_.cloneDeep(_.find(this.questions, referenceProperties)));
            // Fill in all the answers into the responses inside each question.
            articleQuestions.forEach((arQuestion) => {
                arQuestion.programDataSetId = pdsId;
                arQuestion.responses.forEach((arResponse) => {
                    arResponse.answers = _.filter(this.answers, { programDataSetId: pdsId, responseId: arResponse.id });
                });
            });
            pds.articles.push({ programDataSetId: pdsId, articleQuestions, studyFindings });
        });

        return pds;
    },
    onAddArticle: function (event) {
        const $elem = this.$(event.currentTarget);
        const studyId = $elem.closest('[data-study-id]').data('study-id');
        const article = new Article({ studyId });

        article.setProgramId(this.programId);
        article.save()
            .then(() => this.programDetails.fetch())
            .then((result) => this.answers = result.answers)
            .then(() => {
                this.computeAnswers();
                const model = this.collection.findWhere({ studyId });
                model.set('showStudy', true);
                this.render();
            });
    },
    onRetireArticle: function (event) {
        const $elem = this.$(event.currentTarget);
        const studyId = $elem.closest('[data-study-id]').data('study-id');
        const programDataSetId = $elem.closest('[data-program-data-set-id]').data('program-data-set-id');
        const article = new Article({ programDataSetId });
        article.setProgramId(this.programId);

        const $body = this.$el.closest('body');
        $body.css({ cursor: 'wait' });
        $elem.css({ cursor: 'wait' });

        article.destroy()
            .then(() => this.programDetails.fetch())
            .then((result) => this.answers = result.answers)
            .then(() => {
                this.computeAnswers();
                const model = this.collection.findWhere({ studyId });
                model.set('showStudy', true);
            })
            .then(() => this.render())
            .always(() => $body.css({ cursor: 'default'}));

        return false;
    },
    onAddStudy: function () {
        const highestStudyId = this.collection.length ? this.collection.max('studyId').get('studyId') : 0;
        const allQuestions = utils.getResearchCategoryQuestions(this.questions, this.answers);

        const study = new Study({
            studyId: highestStudyId + 1
        });
        study.setProgramId(this.programId);
        study.save()
            .then((result) => {
                this.collection.forEach((pds) => pds.set('showStudy', false));
                result.showStudy = true;
                this.collection.add(this.cloneAnswers(allQuestions, result));
            })
            .then(() => this.render());
    },
    onSaveAndNext: function (event) {
        const $elem = this.$(event.currentTarget);
        const $researchWrapper = $elem.closest(this.ui.researchWrapper);
        const studyId = $elem.closest('[data-study-id]').data('study-id');
        const model = this.collection.findWhere({ studyId });

        // Change to this when we're ready to handle individual article programDataSetId's.
        // const questions = new Questions(this.questions);

        const questions = new Questions(model.get('q_questions'));
        const $responses = $researchWrapper.find('[data-program-data-set-id]').first().find('[data-response-id]');
        const originalAnswers = new SiteAnswers(model.get('q_answers'));
        const programDataSetId = model.get('programDataSetId');

        const answers = this.constructAnswers(questions, $responses, originalAnswers, programDataSetId);

        answers.setSurveyType('articlesStudy');
        answers.setOrganizationId(this.organizationId);
        answers.setProgramId(this.programId);

        if (!programDataSetId) {
            // New study. Answer the 'Study ID' question for them.
            const question = model.get('q_questions').filter((qt) => qt.tag === 'studyid')[0];

            answers.add({
                questionId: question.id,
                responseId: question.responses[0].id,
                answer: model.get('studyId')
            });
        }

        questions.reset(this.questions);
        model.get('articles').forEach((ar) => {
            originalAnswers.reset(_.flatten(_.filter(this.questions, { level1Value: 'Research Evidence', level2Value: 'Study Findings' }).map((q) => q.answers)).filter((aq) => aq.programDataSetId === ar.programDataSetId));
            const $articleResponses = this.$(`[data-program-data-set-id=${ar.programDataSetId}]`).find('[data-response-id');
            const newAnswers = this.constructAnswers(questions, $articleResponses, originalAnswers, ar.programDataSetId);
            answers.add(newAnswers.models);
        });

        const $body = this.$el.closest('body');
        $body.css({ cursor: 'wait' });
        $elem.css({ cursor: 'wait' });

        return answers.save()
            .then(() => this.programDetails.fetch())
            .then((result) => this.answers = result.answers)
            .then(() => this.$el.closest('.scroll-y').animate({ scrollTop: 0 }, 200))
            .then(() => {
                this.computeAnswers();
                const currentIndex = this.collection.findIndex({ studyId });
                if (currentIndex >= 0) {
                    const nextModel = this.collection.at(currentIndex + 1);
                    if (nextModel) {
                        nextModel.set('showStudy', true);
                    }
                }
            })
            .then(() => this.render())
            .always(() => $body.css({ cursor: 'default'}));
    },
    onRender: function () {
        // Make sure they can't change the study id.
        const studyIdQuestion = this.questions.filter((qt) => qt.tag === 'studyid')[0] || {};
        const studyIdQuestionId = studyIdQuestion.id;
        if (studyIdQuestionId) {
            this.$(`input[data-question-id="${studyIdQuestionId}"`).prop('disabled', true);
        }
    },
    constructAnswers: function (questions, $responses, originalAnswers, pdsId) {
        const answers = new SiteAnswers();

        //
        // This is almost exactly the same code as the guts of `saveSurvey()` in survey.js. Please keep in sync, or refactor.
        //
        $responses.each((ix, elem) => {
            const $elem = this.$(elem);
            const questionId = $elem.data('question-id');
            const responseId = $elem.data('response-id');
            const nodeName = $elem.prop('nodeName');
            const nodeType = $elem.attr('type');
            const checked = $elem.is(':checked');
            const checkable = (nodeName === 'OPTION' || nodeType === 'checkbox' || nodeType === 'radio');
            const value = (checkable) ? checked : $elem.val();

            const thisQuestion = questions.get(questionId);

            if (!responseId) {
                return;
            }

            // Bail out if we don't know the question. Happening for Articles, right now.
            if (!thisQuestion) {
                console.log('Unknown questionId', questionId);
                return;
            }

            const responseResponseValue = thisQuestion.get('responses').filter((r) => r.id === responseId )[0].responseValue;

            const answer = originalAnswers.get({ questionId, responseId, programDataSetId: pdsId });

            if (nodeType === 'checkbox' && $elem.data('response-id-0')) {
                // This is a true checkbox representing two possible responseId's, not a Multiple Select rendered as a checkbox.
                const response_unchecked = $elem.data('response-id-0');
                const response_checked = $elem.data('response-id-1');

                const answer_unchecked = this.answers.get({questionId, responseId: response_unchecked});
                const answer_checked = this.answers.get({questionId, responseId: response_checked});

                if (checked) {
                    if (!answer_checked) {
                        answers.add(new Answer({
                            questionId,
                            responseId : response_checked,
                            answer: checked
                        }));
                    }
                    if (answer_unchecked) {
                        // Shouldn't really be necessary, since we're not creating an Answer for an unchecked checkbox.
                        // But some earlier code was doing just that, so we need to retire it, if it is found.
                        answers.add(new Answer({
                            questionId,
                            responseId: response_unchecked,
                            programDataSetId: answer_unchecked.get('programDataSetId'),
                            answerId: answer_unchecked.get('answerId'),
                            answer: answer_unchecked.get('responseValue'),
                            retired: true
                        }));
                    }
                } else {
                    if (answer_checked) {
                        answers.add(new Answer({
                            questionId,
                            responseId: response_checked,
                            programDataSetId: answer_checked.get('programDataSetId'),
                            answerId: answer_checked.get('answerId'),
                            answer: answer_checked.get('responseValue'),
                            retired: true
                        }));
                    }
                }

                // console.log(questionId, responseId, response_unchecked, response_checked, checked, !!answer_unchecked, !!answer_checked);
            } else if (nodeName === 'OPTION' && !!$elem.data('single-response')) {
                if (checked) {
                    const selectedValue = $elem.val();

                    if (answer) {
                        const answerId = answer.get('answerId') || answer.get('id');
                        const responseValue = answer.get('responseValue');
                        const programDataSetId = answer.get('programDataSetId');

                        if (responseValue !== selectedValue) {
                            answers.add(new Answer({
                                questionId,
                                responseId,
                                programDataSetId,
                                answerId,
                                answer: responseResponseValue || selectedValue,
                                retired: false
                            }));
                        }
                    } else {
                        answers.add(new Answer({
                            questionId,
                            responseId,
                            programDataSetId: pdsId,
                            answer: responseResponseValue || selectedValue
                        }));
                    }
                }
            } else if (answer) {
                // If there was a previous answer, see if it has changed.
                const answerId = answer.get('answerId') || answer.get('id');
                const responseValue = answer.get('responseValue');

                // If the element is "checkable", the existence of an answer indicates it is in the "true" state, regardless of the "responseValue".
                const hasValue = !!value;
                const hasAnswer = !!answer;
                if ((checkable && hasValue !== hasAnswer) || (!checkable && value !== responseValue)) {
                    answers.add(new Answer({
                        questionId,
                        responseId,
                        programDataSetId: answer.get('programDataSetId'),
                        answerId: answerId,
                        answer: responseResponseValue || value,
                        retired: !value
                    }));
                    // console.log(nodeName, nodeType, checkable, value, responseValue);
                }
            } else {
                // If there was no previous answer, see if the new answer is "positive".
                if (value) {
                    answers.add(new Answer({
                        questionId,
                        responseId,
                        programDataSetId: pdsId,
                        answer: responseResponseValue || value
                    }));
                }
            }
        });

        return answers;
    }
});
