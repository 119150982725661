'use strict';

console.time('start');

const
    Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    $ = require('jquery'),
    // require('polyfill') has to go before require('async')...
    polyfill = require('@babel/polyfill'), // eslint-disable-line no-unused-vars
    async = require('async'),
    //
    // templates needs to be loaded before handlebars
    // and templates and handlebars have to go before any views that use the templates
    // (albeit just the one time here...)
    templates = require('../builds/adminTemplates'),
    handlebars = require('./utilities/handlebars'),
    //
    bootstrap = require('../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'),
    // tableOfContents = require('../models/tableOfContents'),
    // topics = require('../collections/topics'),
    Application  = require('./views/application/application'),
    TheApplication = Marionette.Application.extend({
        region: {
            el: 'div[data-region=application]',
            replaceElement: true
        },
        onStart: function () {
            this.showView(new Application());
        }
    }),
    application = new TheApplication();

if (!bootstrap) {
    console.error('admin - missing bootstrap');
}
if (!templates) {
    console.error('admin - missing templates');
}

$(function () {
    async.series([
        function (callback) {
            handlebars.initialize(callback);
        }
    ], function () {
        Backbone.history.start({pushState: true});
        application.start();
    });
});

