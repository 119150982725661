'use strict';

const Marionette = require('backbone.marionette'),
    //
    UserLogin = require('../../singletons/userLogin'),
    PasswordRecovery = require('../../models/passwordRecovery'),
    //
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'recover',
        'data-name': 'recover'
    },
    ui: {
        form: 'form',
        submit: '.js-submit-button',
        username: '.js-username'
    },
    events: {
        'submit': 'onSubmit'
    },
    tagName: 'div',
    template: templates.recover,
    //
    templateContext: function () {
        return {
            userName: UserLogin.get('username')
        };
    },
    onSubmit: function () {
        const username = this.ui.username.val();
        const passwordRecovery = new PasswordRecovery({ email: username });

        UserLogin.set('username', username);

        passwordRecovery.save()
            .fail(function (jqXHR) {
                console.error('passwordReset.fail: status=%s %s response=%s', jqXHR.status, jqXHR.statusText, jqXHR.responseText);
                window.alert('The password request attempt has failed.\n\nPlease enter a valid email address.');
                this.trigger('modal:hide');
            })
            .then(() => this.trigger('modal:hide'));

        return false;
    }
});
