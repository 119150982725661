'use strict';

const Backbone = require('backbone'),
    Cookies = require('js-cookie'),
    //
    User = require('../singletons/user');

const UserLogin = Backbone.Model.extend({
    //
    url: '/user/login',
    //
    initialize: function () {
        this.set('accountName', '');
        const authorizationHeader = Cookies.get('creds', { path: '/' });
        if (authorizationHeader) {
            this.set('authorizationHeader', authorizationHeader);
            const authorizationParts = window.atob(authorizationHeader.replace(/^Basic /, '')).split(':');
            const username = authorizationParts[1];
            const password = authorizationParts[2];
            this.readyPromise = this.refresh(username, password, true)
                .then(() => this.trigger('autoLogin'));
        }
    },
    ready: function () {
        return this.readyPromise;
    },
    refresh: function (username, password, rememberMe) {
        const data = {
            email: username,
            password: password
        };
        return this.save(data)
            .then(() => {
                this.clearAuthorizationHeader();
                User.set(this.toJSON());
                this.clear();
                this.setAuthorizationHeader(username, password, rememberMe);
            })
            .fail(() => this.clearAuthorizationHeader());
    },
    login: function (username, password, rememberMe) {
        this.refresh(username, password, rememberMe)
            .then(() => this.loggedIn());
    },
    getAuthorizationHeader: function () {
        return this.get('authorizationHeader');
    },
    setAuthorizationHeader: function (username, password, rememberMe) {
        const authorizationHeader = 'Basic ' + window.btoa(`:${username}:${password}`);
        this.set('authorizationHeader', authorizationHeader);
        if (rememberMe) {
            Cookies.set('creds', authorizationHeader, { path: '/', expires: 24 });
        } else {
            Cookies.remove('creds', { path: '/' });
        }
    },
    clearAuthorizationHeader: function () {
        this.unset('authorizationHeader');
        Cookies.remove('creds', { path: '/' });
    },
    loggedIn: function () {
        this.trigger('loggedIn');
    },
    loggedOut: function () {
        this.clearAuthorizationHeader();
        this.trigger('loggedOut');
    }
});

module.exports = new UserLogin();
