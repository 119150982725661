'use strict';

const Marionette = require('backbone.marionette'),
    Radio = require('backbone.radio'),
    autocomplete = require('autocompleter'),
    $ = require('jquery'),
    //
    Users = require('../../collections/users'),
    //
    UserView = require('../user/user'),
    UsersList = require('../users/usersList/usersList'),
    //
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'organization-users',
        'data-name': 'organization-users'
    },
    ui: {
        createUser: '.js-create-user',
        addUsers: '.js-add-users',
        usersDone: '.js-users-done',
        //
        userName: '[name="userName"]',
        usersCount: '.js-users-count'
    },
    events: {
        'click @ui.createUser': 'onCreateUser',
        'click @ui.addUsers': 'onAddUsers',
        'click @ui.usersDone': 'onUsersDone',
        'click [data-action="add-user"]': 'onAddUser',
        'click [data-action="remove-user"]': 'onRemoveUser'
    },
    regions: {
        usersList: {el: 'div[data-region=organization-users-list]', replaceElement: true}
    },
    tagName: 'div',
    template: templates.organizationUsers,
    //
    initialize: function (options) {
        this.organizationUserModel = options.organizationUserModel;
        this.listenTo(this.collection, 'sync', () => {
            this.ui.usersCount.text(this.collection.length);
        });
    },
    onRender: function () {
        autocomplete({
            input: this.ui.userName[0],
            className: 'list-group scroll-y',
            emptyMsg: 'No matching Users found',
            minLength: 1,
            showOnFocus: true,
            debounceWaitMs: 200,
            fetch: function (text, update) {
                const users = new Users();
                users.setMatchingText(text);
                users.fetch()
                    .then((results) => {
                        results.forEach((user) => user.label = user.fullName);
                        update(results);
                    });
            },
            render: (item) => {
                return $(templates.organizationAddUserRow(item))[0];
            },
            onSelect: (item) => {
                this.organizationUserModel.setUserId(item.id);
                this.organizationUserModel.save()
                    .then(this.refreshUsers());
            }
        });

        this.usersList = this.showChildView('usersList', new UsersList({
            collection: this.collection,
            rowTemplate: templates.organizationUsersListRow
        }));
    },
    onAddUsers: function (event) {
        this.$(event.currentTarget).closest('.not-editing').removeClass('not-editing').addClass('is-editing');
        return false;
    },
    onUsersDone: function (event) {
        this.$(event.currentTarget).closest('.is-editing').removeClass('is-editing').addClass('not-editing');
        return false;
    },
    onAddUser: function (event) {
        const userId = this.$(event.currentTarget).data('add-user');
        this.organizationUserModel.setUserId(userId);
        this.organizationUserModel.save()
            .then(this.refreshUsers());
    },
    onRemoveUser: function (event) {
        const userId = this.$(event.currentTarget).data('remove-user');
        this.organizationUserModel.setUserId(userId);
        this.organizationUserModel.destroy()
            .then(this.refreshUsers());
    },
    onCreateUser: function () {
        const channel = Radio.channel('user');
        channel.reply('user', (userId) => {
            this.organizationUserModel.setUserId(userId);
            this.organizationUserModel.save()
                .then(this.refreshUsers());
        });
        this.trigger('modal:show', new UserView(0, true));
        return false;
    },
    refreshUsers: function () {
        this.collection.reset();
        this.collection.fetch();
    }
});
