'use strict';

const Backbone = require('backbone'),
    StatusModel = require('../models/status');

module.exports = Backbone.Collection.extend({
    model: StatusModel,
    //
    url: function () {
        return '/program/status/' + this.programId;
    },
    //
    programId: 0,
    initialize: function (options) {
        this.programId = options.programId;
    },
    getProgramId: function () {
        return this.programId;
    },
    setProgramId: function (programId) {
        this.programId = programId;
    },
    save: function (options) {
        return Backbone.sync('update', this, options)
            .then(() => this.trigger('saved'));
    }
});
