'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    //
    UsersHeader = require('./usersHeader/usersHeader'),
    UsersList = require('./usersList/usersList'),
    Users = require('../../collections/users'),
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'users scroll-y',
        'data-name': 'users'
    },
    childViewEvents: {
        'refresh.users': 'refresh'
    },
    events: {
        'scroll': 'onScroll'
    },
    regions: {
        usersHeader: {el: 'div[data-region=users-header]', replaceElement: true},
        usersList: {el: 'div[data-region=users-list]', replaceElement: true}
    },
    tagName: 'div',
    template: templates.users,
    //
    initialize: function () {
        this.currentScrollHeight = 0;
        this.collections = {
            users: new Users()
        };
    },
    onScroll: function () {
        const scrollHeight = this.el.scrollHeight;
        const scrollPos = Math.floor(this.el.clientHeight + this.el.scrollTop);
        const isBottom = scrollHeight - 300 < scrollPos;

        // console.log('this.currentScrollHeight=', this.currentScrollHeight, 'scrollHeight=', scrollHeight, 'scrollPos=', scrollPos, 'isBottom=', isBottom);

        if (isBottom && this.currentScrollHeight < scrollHeight) {
            // console.log('onScroll: load more');
            this.collections.users.fetch({remove: false});
            this.currentScrollHeight = scrollHeight;
        }

        // This is an alternative scroll handler, that will continually try to fetch more rows. It has no concept of "having reached the bottom".
        //         const scrollY = this.el.scrollHeight - this.el.scrollTop;
        //         const offsetHeight = this.el.offsetHeight;
        //         const offset = scrollY - offsetHeight;
        //
        //         // console.log('scrollY=', scrollY, 'offsetHeight=', offsetHeight, 'offset=', offset);
        //
        //         if (offset <= 0) {
        //             // console.log('onScroll: load more');
        //             this.collections.users.fetch({remove: false});
        //         }
    },
    onRender: function () {
        this.usersHeader = this.showChildView('usersHeader', new UsersHeader({collection: this.collections.users}));
        this.usersList = this.showChildView('usersList', new UsersList({
            collection: this.collections.users,
            rowTemplate: templates.usersListRow
        }));
    },
    refresh: function () {
        this.currentScrollHeight = 0;
        this.collections.users.reset();
        this.collections.users.fetch();
    }
});
