'use strict';

const Marionette = require('backbone.marionette'),
    //
    validator = require('jquery-validation').validator,
    $ = require('jquery'),
    _ = require('lodash'),
    //
    UserLogin = require('../../singletons/userLogin'),
    //
    configuration = require('../../utilities/configuration'),
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'reset',
        'data-name': 'reset'
    },
    ui: {
        form: 'form',
        password: 'input[name="password"]',
        passwordRepeat: 'input[name="password-repeat"]',
        passwordToggle: '.js-password-toggle',
        submit: '.js-submit-button'
    },
    events: {
        'blur @ui.password': 'validateResetPasswordForm',
        'click @ui.passwordToggle': 'togglePassword',
        'click @ui.submit': 'submit'
    },
    tagName: 'div',
    template: templates.reset,
    //
    initialize: function (token) {
        this.token = token;
        this.initValidator();
    },
    templateContext: function () {
        return {
            token: this.token
        };
    },
    togglePassword: function (event) {
        const $password = this.$(event.currentTarget).parents('.input-group').find('.js-password');

        let type = $password.attr('type');
        let iconName;

        if (type === 'password') {
            type = 'text';
            iconName = 'fa-eye-slash';
        } else {
            type = 'password';
            iconName = 'fa-eye';
        }

        $password.attr('type', type);
        this.$(event.currentTarget).find('.fa-eye,.fa-eye-slash').removeClass('fa-eye fa-eye-slash').addClass(iconName);

        return false;
    },
    submit: function () {
        if (this.validateResetPasswordForm()) {
            const data = _.mapValues(_.keyBy(this.ui.form.serializeArray(), 'name'), 'value');
            const url = configuration.apiUrlBase + this.ui.form.attr('action');

            $.post(url, data).promise()
                .then((result) => {
                    UserLogin.loggedOut();
                    UserLogin.set('username', result.email);
                })
                .then(() => this.trigger('navigate', configuration.initialView || 'login'))
                .fail((jqXHR) => {
                    console.error('passwordReset.fail: status=%s %s response=%s', jqXHR.status, jqXHR.statusText, jqXHR.responseText);
                    window.alert('The password reset attempt has failed.\n\nPlease obtain a new password recovery link\nfrom the login view. Redirecting now.');
                    this.trigger('navigate', 'login');
                });
        }

        return false;
    },
    keyupTimer: 0,
    validatePassword: function (password) {
        const errors = [],
            // rule.test returns truthy if rule passes
            rules = [
                {
                    test: function (pw) { return (pw && pw.length >= 8); },
                    message: 'Must contain at least 8 characters'
                },
                {
                    test: function (pw) { return /.*[a-z].*/.exec(pw); },
                    message: 'Must contain at least one lower case letter'
                },
                {
                    test: function (pw) { return /.*[A-Z].*/.exec(pw); },
                    message: 'Must contain at least one upper case letter'
                },
                {
                    test: function (pw) { return /.*[0-9].*/.exec(pw); },
                    message: 'Must contain at least one number'
                },
                {
                    test: function (pw) { return /.*[^A-Za-z0-9].*/.exec(pw); },
                    message: 'Must contain at least one special character'
                },
                {
                    test: function (pw) { return !/^.*?:$/.exec(pw); },
                    message: 'Must not end with ":"'
                }
            ];
        rules.forEach(function (rule) {
            if (!rule.test(password)) {
                errors.push(rule.message);
            }
        });
        return errors.length > 0 ? errors : null;
    },
    validatorRules: {
        rules: {
            password: 'passwordValidation',
            'password-repeat': 'passwordsMatch'
        },
        messages: {
            'password-repeat': {passwordsMatch: 'Passwords must match'}
        },
        errorPlacement: function (error, element) {
            error.insertAfter(element.parents('.input-group'));
        }
    },
    initValidator: function () {
        const that = this;
        let validatorErrors;
        validator.addMethod('passwordValidation',
            function (value) {
                validatorErrors = that.validatePassword(value);
                return !validatorErrors;
            },
            function () {
                return validatorErrors ? validatorErrors.join('<br>') : null;
            }
        );
        validator.addMethod('passwordsMatch', function () {
            const p1 = that.ui.password.val(),
                p2 = that.ui.passwordRepeat.val();
            return ((!p1 || p1 === '') && (!p2 || p2 === '')) || p1 === p2;
        }, 'Passwords must match');
    },
    validateResetPasswordForm: function () {
        const form = this.ui.form;
        form.validate(this.validatorRules);
        return form.valid();
    }
});
