'use strict';

const Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    _ = require('lodash'),
    //
    Answer = require('../../models/answer'),
    Answers = require('../../collections/answers'),
    Program = require('../../models/program'),
    ProgramInfo = require('../../models/programInfo'),
    ProgramStatus = require('../../collections/programStatus'),
    Questions = require('../../collections/questions'),
    SiteStatus = require('../../collections/siteStatus'),
    //
    ZipcodePickerView = require('./zipcode'),
    //
    Enumerations = require('../../singletons/enumerations'),
    //
    utils = require('../../utilities/utils'),
    templates = require('../../utilities/handlebars').templates;

const Survey = Backbone.Model.extend({
    urlRoot: function () {
        return `/survey/${this.surveyType}/${this.get('programId')}`;
    },
    idAttribute: 'organizationId',
    getSurveyType: function () {
        return this.surveyType;
    },
    setSurveyType: function (surveyType) {
        this.surveyType = surveyType;
    }
});

const DIRECTION_FORWARD = 'forward',
    DIRECTION_BACKWARD = 'backward';

module.exports = Marionette.View.extend({
    attributes: {
        class: 'survey',
        'data-name': 'survey'
    },
    ui: {
        firstPage: '.js-first-page',
        previousPage: '.js-previous-page',
        nextPage: '.js-next-page',
        lastPage: '.js-last-page',
        saveSurvey: '.js-save-survey',
        bothNeither: '[data-response-id=""]'
    },
    events: {
        'click @ui.firstPage': 'onFirstPage',
        'click @ui.previousPage': 'onPreviousPage',
        'click @ui.nextPage': 'onNextPage',
        'click @ui.lastPage': 'onLastPage',
        'click @ui.saveSurvey': 'onSaveSurveyClicked',
        'click @ui.bothNeither': 'onBothNeitherClicked'
    },
    regions: {
        zipcodePicker: {el: 'div[data-region=zipcode-picker]', replaceElement: true}
    },
    tagName: 'div',
    getTemplate: function () {
        return this.template;
    },
    //
    initialize: function (surveyType, organizationId, programId, template) {
        this.programDataSetTypeId = Enumerations.get('programDataSetTypeByName')[surveyType].id;

        this.template = (template && templates[template]) ? templates[template] : templates.survey;

        switch (surveyType) {
        case 'developer':
            this.model = new Program({ id: programId });
            break;
        case 'consumer':
        case 'site':
            this.model = new ProgramInfo({ organizationId, programId });
            break;
        }
        const modelPromise = this.model.fetch();

        this.questions = new Questions();
        this.questions.setProgramDataSetTypeId(this.programDataSetTypeId);
        const questionsPromise = this.questions.fetch()
            .then(() => {
                this.pages = Object.keys(this.questions.groupBy('smPageNumber'));
            });

        // this.model = new Backbone.Model({ questions: this.questions.toJSON() });

        this.answers = new Answers();
        this.answers.setSurveyType(surveyType);
        this.answers.setOrganizationId(organizationId);
        this.answers.setProgramId(programId);
        const answersPromise = this.answers.fetch();

        this.survey = new Survey({ organizationId, programId });
        this.survey.setSurveyType(surveyType);
        const surveyPromise = this.survey.fetch();

        if (surveyType === 'developer') {
            this.programStatus = new ProgramStatus({ programId });
        }
        const programStatusPromise = (this.programStatus) ? this.programStatus.fetch() : null;

        Promise.all([ modelPromise, questionsPromise, answersPromise, surveyPromise, programStatusPromise ])
            .then(() => {
                this.filterQuestions();
                const lastSavedSurveyPage = this.survey.get('lastSavedSurveyPage');
                const lastVisitedIndex = this.pages.indexOf(lastSavedSurveyPage);
                // If lastSavedSurveyPage isn't found, index will be -1, so we will start at zero.
                this.updateProgress(lastVisitedIndex + 1);
                this.model.set('maxPageIndex', this.pages.length-1);
                this.model.set('maxPageNumber', this.pages[this.pages.length-1]);
                this.model.set('surveyType', surveyType);
                this.render();
            });
    },
    scrollToTop: function () {
        this.$el.parent().scrollTop(0);
    },
    onFirstPage: function () {
        this.direction = DIRECTION_FORWARD;
        this.updateProgress(0);
        this.scrollToTop();
        this.render();
    },
    onPreviousPage: function () {
        this.direction = DIRECTION_BACKWARD;
        const currentIndex = this.model.get('pageIndex');
        if (currentIndex > 0) {
            this.updateProgress(currentIndex - 1);
            this.scrollToTop();
            this.render();
        }
    },
    onNextPage: function () {
        this.direction = DIRECTION_FORWARD;
        const currentIndex = this.model.get('pageIndex'),
            lastIndex = this.pages.length;
        let nextIndex = currentIndex + 1;
        // Make sure we can process "Save & Next" on the very last page.
        if (currentIndex < lastIndex) {
            // Disable the navigation buttons so that the page is not rendered until after the "Save" has completed.
            // Make sure that we end with a call to this.render() so that the buttons get enabled again.
            this.ui.firstPage.prop('disabled', true);
            this.ui.previousPage.prop('disabled', true);
            this.ui.nextPage.prop('disabled', true);
            this.ui.lastPage.prop('disabled', true);
            this.survey.save({ lastSavedSurveyPage: this.pages[currentIndex] });
            this.saveSurvey()
                .then((skipToIndex) => {
                    if (skipToIndex >= 0) {
                        nextIndex = skipToIndex;
                    }

                    if (nextIndex >= lastIndex) {
                        // Survey is done.

                        // Special case here. Last page of the survey always contains a set of radio buttons.
                        // User cannot leave without selecting one of them.
                        if (this.$('input[type="radio"][data-response-id]').length && !this.$('input[type="radio"][data-response-id][data-response-id]:checked').length) {
                            window.alert('You must select one of the options.');
                            nextIndex = currentIndex;
                            return;
                        }

                        const surveyType = this.answers.getSurveyType();

                        let developerSurveyStatus,
                            siteStatus;

                        switch (surveyType) {
                        case 'developer':
                            developerSurveyStatus = this.programStatus.filter({ statusName: 'developerSurveyComplete' })[0];
                            if (developerSurveyStatus) {
                                developerSurveyStatus.setCompleted(true);
                                this.programStatus.save();
                            }
                            break;
                        case 'consumer':
                        case 'site':
                            siteStatus = new SiteStatus(null, { programId: this.survey.get('programId'), siteId: this.answers.getOrganizationId() });
                            siteStatus.fetch()
                                .then(() => {
                                    const statusName = (surveyType === 'site') ? 'siteSurveyComplete' : 'consumerSurveyComplete';
                                    return siteStatus.filter({ statusName })[0];
                                })
                                .then((status) => {
                                    if (status) {
                                        status.setCompleted(true);
                                        siteStatus.save();
                                    }
                                });
                            break;
                        }

                        // ALl done. Start back at the beginning, if they visit again.
                        this.survey.save({ lastSavedSurveyPage: null });

                        // Survey is done. Go back to where we came from?
                        Backbone.history.history.back();
                    }
                })
                .always(() => {
                    this.updateProgress(nextIndex);
                    this.scrollToTop();
                    this.render();
                });
        }
    },
    onLastPage: function () {
        this.direction = DIRECTION_FORWARD;
        const lastPageIndex = this.pages.length - 1;
        this.updateProgress(lastPageIndex);
        this.scrollToTop();
        this.render();
    },
    // This method is making a few assumptions.
    // The intent is to handle the case where a row of checkboxes have a "Both" and a "Neither" option.
    // We identify this by not having a data-response-id on the element (since these are pseudo answers).
    // One assumption is that we only care about the "checked" state of items that have a response-id.
    // Another assumption is that the first item that *does not* have a response-id corresponds to "Both".
    // Any other item that *does not* have a response-id corresponds to "Neither".
    onBothNeitherClicked: function (event) {
        const $elem = this.$(event.currentTarget);

        const $checkboxSiblings = $elem.closest('tr').find('input[type="checkbox"]');
        $checkboxSiblings.prop('checked', false);

        // If this checkbox is the last one that has no response-id, it is the "Neither" option.
        // If this checkbox is the second last one that has no response-id, it is the "Both" option.

        // Find all siblings that have no response-id. The first one will be "Both".
        const index = $checkboxSiblings.filter((idx, elem) => !this.$(elem).data('response-id')).toArray().indexOf($elem[0]);
        if (index === 0) {
            $checkboxSiblings.filter((idx, elem) => this.$(elem).data('response-id')).prop('checked', true);
        }
    },
    updateProgress: function (pageIndex) {
        pageIndex = Math.min(pageIndex, this.pages.length-1);
        this.model.set('pageProgress', Math.floor(pageIndex * 100 / (this.pages.length-1)));
        this.model.set('pageIndex', pageIndex);
        this.model.set('pageNumber', this.pages[pageIndex]);
        this.setupZipPicker(this.pages[pageIndex]);
    },
    setupZipPicker: function (pageNumber) {
        const questions = this.model.get('questions'),
            pageQuestions = questions.filter((q) => q.smPageNumber === pageNumber),
            responses = _.flatten(pageQuestions.map((q) => q.responses)),
            responseCodes = responses.map((r) => r.responseCode),
            zipcodeResponseCodes = ['bpccq3ar1', 'bpccq3br1', 'bpccq3cr1', 'bpccq3dr1', 'bpccq3er1'];

        this.zipcodePicker = !!responseCodes.filter((rc) => zipcodeResponseCodes.includes(rc)).length;
        this.model.set('zipcodePicker', this.zipcodePicker);

        if (this.zipcodePicker) {
            if (!this.zipcodeModel) {
                this.zipcodeModel = new Backbone.Model();
            } else {
                this.zipcodeModel.clear();
            }
            this.zipcodeModel.set('pageQuestions', pageQuestions);
            this.zipcodeModel.set('responses', responses);
        }
    },
    onSaveSurveyClicked: function () {
        this.saveSurvey();
    },
    saveSurvey: function () {
        let skipToIndex = -1;

        const answers = new Answers();
        answers.setSurveyType(this.answers.getSurveyType());
        answers.setOrganizationId(this.answers.getOrganizationId());
        answers.setProgramId(this.answers.getProgramId());

        //
        // This is almost exactly the same code as `constructAnswers()` in researchTab.js. Please keep in sync, or refactor.
        //
        this.$('[data-response-id]').each((ix, elem) => {
            const $elem = this.$(elem);
            const questionId = $elem.data('question-id');
            const responseId = $elem.data('response-id');
            const nodeName = $elem.prop('nodeName');
            const nodeType = $elem.attr('type');
            const checked = $elem.is(':checked');
            const checkable = (nodeName === 'OPTION' || nodeType === 'checkbox' || nodeType === 'radio');
            const value = (checkable) ? checked : $elem.val();

            const thisQuestion = this.questions.get(questionId);
            const skipQuestion = thisQuestion.get('smSkipQuestion');

            if (!responseId) {
                return;
            }

            const responseResponseValue = thisQuestion.get('responses').filter((r) => r.id === responseId )[0].responseValue;

            const answer = this.answers.get({ questionId, responseId });

            if (nodeType === 'checkbox' && $elem.data('response-id-0')) {
                // This is a true checkbox representing two possible responseId's, not a Multiple Select rendered as a checkbox.
                // Some types of questions haveIde the 'Yes' Response at index 0, and others have the 'Yes' Response at index 1.
                const response_index_0 = $elem.data('response-id-0');
                const response_index_1 = $elem.data('response-id-1');
                const response_id_no_index = $elem.data('response-index-no');
                const response_unchecked = (response_id_no_index) ? response_index_1 : response_index_0;
                const response_checked = (response_id_no_index) ? response_index_0 : response_index_1;

                const answer_unchecked = this.answers.get({questionId, responseId: response_unchecked});
                const answer_checked = this.answers.get({questionId, responseId: response_checked});

                if (checked) {
                    if (!answer_checked) {
                        answers.add(new Answer({
                            questionId,
                            responseId : response_checked,
                            answer: checked
                        }));
                    }
                    if (answer_unchecked) {
                        // Shouldn't really be necessary, since we're not creating an Answer for an unchecked checkbox.
                        // But some earlier code was doing just that, so we need to retire it, if it is found.
                        answers.add(new Answer({
                            questionId,
                            responseId: response_unchecked,
                            programDataSetId: answer_unchecked.get('programDataSetId'),
                            answerId: answer_unchecked.get('answerId'),
                            answer: answer_unchecked.get('responseValue'),
                            retired: true
                        }));
                    }
                } else {
                    if (answer_checked) {
                        answers.add(new Answer({
                            questionId,
                            responseId: response_checked,
                            programDataSetId: answer_checked.get('programDataSetId'),
                            answerId: answer_checked.get('answerId'),
                            answer: answer_checked.get('responseValue'),
                            retired: true
                        }));
                    }
                }

                // console.log(questionId, responseId, response_unchecked, response_checked, checked, !!answer_unchecked, !!answer_checked);
            } else if (nodeName === 'OPTION' && !!$elem.data('single-response')) {
                if (checked) {
                    const selectedValue = $elem.val();

                    if (answer) {
                        const answerId = answer.get('answerId');
                        const responseValue = answer.get('responseValue');
                        const programDataSetId = answer.get('programDataSetId');

                        if (responseValue !== selectedValue) {
                            answers.add(new Answer({
                                questionId,
                                responseId,
                                programDataSetId,
                                answerId,
                                answer: responseResponseValue || selectedValue,
                                retired: false
                            }));
                        }
                    } else {
                        answers.add(new Answer({
                            questionId,
                            responseId,
                            answer: responseResponseValue || selectedValue
                        }));
                    }
                }
            } else if (answer) {
                // If there was a previous answer, see if it has changed.
                const answerId = answer.get('answerId');
                const responseValue = answer.get('responseValue');

                // If the element is "checkable", the existence of an answer indicates it is in the "true" state, regardless of the "responseValue".
                const hasValue = !!value;
                const hasAnswer = !!answer;
                if ((checkable && hasValue !== hasAnswer) || (!checkable && value !== responseValue)) {
                    // Make sure we don't merge here. Unchecking an override questions will retire it, and we don't want to unretire due to the "Other" answer.
                    answers.add(new Answer({
                        questionId,
                        responseId,
                        programDataSetId: answer.get('programDataSetId'),
                        answerId: answerId,
                        answer: responseResponseValue || value,
                        retired: !value
                    }));
                    // console.log(nodeName, nodeType, checkable, value, responseValue);
                }
            } else {
                // If there was no previous answer, see if the new answer is "positive".
                // Merging with a previous answer allows override questions to get the "Other" answer.
                if (value) {
                    answers.add(new Answer({
                        questionId,
                        responseId,
                        answer: responseResponseValue || value
                    }), { merge: true });
                }
            }

            if (value && skipQuestion) {
                const responses = thisQuestion.get('responses') || [];
                const response = responses.find((r) => r.id === responseId) || {};
                const responseValue = response.response;
                const skipParts = skipQuestion.split(',');
                const skipCondition = skipParts[0].toLocaleLowerCase();
                const skipDestination = skipParts[1];

                if (skipCondition === `if ${responseValue}`.toLocaleLowerCase()) {
                    const skipToQuestion = skipDestination.match(/skip to (.*)/)[1];
                    const skipToPageModel = this.questions.find({ smQuestionNumber: skipToQuestion });
                    if (!skipToPageModel) {
                        window.alert(`Skip to ${skipToQuestion}. Question Not Found!`);
                    } else {
                        const skipToPage = skipToPageModel.get('smPageNumber');
                        skipToIndex = this.pages.indexOf(skipToPage);
                    }
                }
            }
        });

        return answers.save()
            .then(() => this.answers.fetch())
            .then(() => this.filterQuestions())
            .then(() => skipToIndex);
    },
    filterQuestions: function () {
        const questions = this.questions.toJSON();
        const questionsGrouped = _.groupBy(questions, 'smQuestionNumber');
        const questionNumbers = Object.keys(questionsGrouped);

        questions.forEach((q) => {
            // Inject the programName into the question.
            if (q.question.match(/{{programName}}/)) {
                q.question = q.question.replaceAll('{{programName}}', this.model.get('name'));
            }

            const responses = q.responses || [];
            responses.forEach((r) => {
                const responseAnswer = this.answers.get({ questionId: q.id, responseId: r.id });
                r.answers = (responseAnswer) ? [ responseAnswer.toJSON() ] : null;
            });
            q.responses = responses;

            if (q.cbQuestionType === 'multiselectTable' || q.cbQuestionType === 'multiselectTableWithOptionalText') {
                utils.multiselectTableMetaData(q);
            }

            const displayIf = 'Only display if response in ';

            if (q.smSkipQuestion && q.smSkipQuestion.startsWith(displayIf)) {
                const questionNumber = q.smSkipQuestion.substring(displayIf.length);
                if (questionsGrouped[questionNumber].length === 1) {
                    q.hideIf = !questionsGrouped[questionNumber][0].responses.filter((r) => !r.name.localeCompare(q.profileLabel, {}, { sensitivity: 'base' })).filter((r) => !!r.answers).map((a) => a.responseValue)[0];
                } else {
                    let filteredQuestions = _.filter(questionsGrouped[questionNumber], { profileLabel: q.profileLabel });
                    if (filteredQuestions.length) {
                        q.hideIf = !filteredQuestions[0].responses.filter((r) => !!r.answers).map((a) => a.responseValue)[0];
                    } else {
                        filteredQuestions = _.filter(questionsGrouped[questionNumber], { question: q.question });
                        q.hideIf = !_.flatten(filteredQuestions.map((m) => m.responses)).filter((r) => !!r.answers).length;
                    }
                }
            }
        });

        questionNumbers.forEach((qNumber) => {
            if (qNumber && questionsGrouped[qNumber].length > 1){
                // hideIf can be true, false or undefined. Reject any that are true.
                const groupedQuestions = _.reject(questionsGrouped[qNumber], { hideIf: true });
                const columnTitles = _.uniq(groupedQuestions.map((q) => q.profileLabel.split(' - ')[0]));
                let firstQuestionFound = false;
                groupedQuestions.forEach((q) => {
                    q.multiQuestion = true;
                    if (firstQuestionFound) {
                        q.multiQuestionFollowOn = true;
                    } else {
                        firstQuestionFound = true;
                        q.columnTitles = columnTitles;
                        q.multiQuestionFirst = true;
                    }
                    q.multiQuestionOffset = columnTitles.indexOf(q.profileLabel.split(' - ')[0]);
                    q.profileLabel = q.profileLabel.split(' - ')[1] || q.profileLabel;
                });
                if (groupedQuestions.length) {
                    groupedQuestions[groupedQuestions.length-1].multiQuestionLast = true;
                }
            }
        });

        this.model.set('questions', questions);
    },
    onRender: function () {
        if (this.zipcodePicker) {
            this.zipcodePickerView = this.showChildView('zipcodePicker', new ZipcodePickerView({
                model: this.zipcodeModel
            }));
        }
        // When rendering tables, we generally don't want to display the first question, since the "informational" question
        // describes what the user should do. But sometimes, there is no "informational" question, so in that case we really
        // do want to display the first question. This just takes a general approach that hides all question text when there
        // is an "informational" question on the page.
        this.$('.js-question .js-question-ordinal').toggle(this.$('.informational').length === 0);

        // We should skip this page and go on to the "next" one if there are questions to be displayed, but none are due
        // to constraints from previous answers.
        const actualQuestionsOnPage = this.questions.filter({ smPageNumber: this.model.get('pageNumber') }).filter((q) => q.get('cbQuestionType') !== 'none').length;
        const displayedQuestions = this.$('[data-question-id]').length;
        if (actualQuestionsOnPage && !displayedQuestions) {
            switch (this.direction) {
            case DIRECTION_FORWARD:
                this.onNextPage();
                break;
            case DIRECTION_BACKWARD:
                this.onPreviousPage();
                break;
            }
        }
    }
});
