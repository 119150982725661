'use strict';

const Backbone = require('backbone');

module.exports = Backbone.Model.extend({
    urlRoot: function () {
        return `/program/info/${this.programId}/${this.organizationId}`;
    },
    initialize: function (options) {
        this.programId = options.programId;
        this.organizationId = options.organizationId;
    },
    getProgramId: function () {
        return this.programId;
    },
    setProgramId: function (programId) {
        this.programId = programId;
    },
    getOrganizationId: function () {
        return this.organizationId;
    },
    setOrganizationId: function (organizationId) {
        this.siteId = organizationId;
    }
});
