'use strict';

const Marionette = require('backbone.marionette'),
    //
    DeliverySite = require('../../models/deliverySite'),
    //
    SitePrograms = require('../../collections/sitePrograms'),
    //
    SiteView = require('./site'),
    //
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'site',
        'data-name': 'site'
    },
    childViewEvents: {
        'navigate': 'onNavigate'
    },
    childViewTriggers: {
        'modal:show': 'modal:show',
        'modal:hide': 'modal:hide'
    },
    regions: {
        site: { el: 'div[data-region=site]', replaceElement: true }
    },
    tagName: 'div',
    template: templates.siteRegion,
    //
    initialize: function (siteId, isModal) {
        this.siteId = siteId;
        this.isModal = isModal;
        this.programs = new SitePrograms({ siteId });
    },
    onRender: function () {
        this.showChildView('site', new SiteView(this.siteId, {
            isModal: this.isModal,
            programs: this.programs,
            siteFactory: DeliverySite,
            rowTemplate: templates.siteProgramsListRow,
            title: 'Delivery Site'
        }));
    },
    onNavigate: function (route) {
        this.trigger('navigate', route);
    }
});
