'use strict';

const Backbone = require('backbone');

const StatusModel = Backbone.Model.extend({
    setCompleted: function (completed) {
        if (completed) {
            this.set('status', 'completed');
        } else {
            this.set('status', 'pending');
        }
    }
});

module.exports = StatusModel;