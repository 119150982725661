'use strict';

const Backbone = require('backbone');

module.exports = Backbone.Model.extend({
    urlRoot: '/program/preview',
    padResponses: 'numelig,numcomp,resprate',    // Delivery Sites answers to pad
    sync: function (method, model, options) {
        options = options || {};
        options.data = {
            compare: 0,
            padResponses: this.padResponses
        };
        return Backbone.sync.apply(this, [method, model, options]);
    },
    getPadResponses: function () {
        return this.padResponses;
    },
    setPadResponses: function (padResponses) {
        this.padResponses = padResponses;
    }
});
