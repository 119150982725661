'use strict';

const Users = require('./users');

module.exports = Users.extend({
    //
    url: function () {
        return '/users/site/' + this.siteId;
    },
    //
    siteId: 0,
    //
    initialize: function (options) {
        this.siteId = options.siteId;
        this.on('reset', () => {
            this.offset = 0;
        });
    },
    getSiteId: function () {
        return this.siteId;
    },
    setSiteId: function (siteId) {
        this.siteId = siteId;
    }
});
