'use strict';

const Marionette = require('backbone.marionette'),
    Radio = require('backbone.radio'),
    ClassicEditor = require('@ckeditor/ckeditor5-build-classic'),
    _ = require('lodash'),
    //
    templates = require('../../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'program-email',
        'data-name': 'program-email'
    },
    ui: {
        form: 'form',
        save: '.js-save-button',
        send: '.js-send-button',
        reset: '.js-reset-button',
        message: 'textarea[name="message"]'
    },
    events: {
        'click @ui.save': 'onSave',
        'click @ui.send': 'onSend',
        'click @ui.reset': 'onReset'
    },
    tagName: 'div',
    template: templates.programEmail,
    //
    initialize: function (options) {
        this.templateModel = options.templateModel;
        this.model.fetch()
            .then(() => this.render());
    },
    onRender: function () {
        ClassicEditor
            .create(this.ui.message[0])
            .then(editor => {
                this.editor = editor;
                this.editor.isReadOnly = this.model.get('viewOnly');
            })
            .catch(error => {
                console.error( 'There was a problem initializing the editor.', error );
            });
    },
    onSave: function () {
        const data = _.mapValues(_.keyBy(this.ui.form.serializeArray(), 'name'), 'value');
        data.message = this.editor.getData();
        this.model.save(data);
        this.trigger('modal:hide');
        return false;
    },
    onSend: function () {
        const data = _.mapValues(_.keyBy(this.ui.form.serializeArray(), 'name'), 'value');
        data.message = this.editor.getData();
        this.model.save(data)
            // Signal back to the caller to "Send" after saving.
            .then(() => {
                const channel = Radio.channel('programEmail');
                channel.request('sendEmail');
                this.trigger('modal:hide');
            });
        return false;
    },
    onReset: function () {
        this.templateModel.set('id', this.model.id);
        this.templateModel.fetch()
            .then((data) => this.model.set(data))
            .then(() => this.render());
    }
});
