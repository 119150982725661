'use strict';

const Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    configuration = require('../../utilities/configuration'),
    sync = Backbone.sync;

let UserLoginHasLoaded = false;

/* eslint-disable no-use-before-define */
Backbone.sync = (method, model, options) => {
    const authorizationHeader = (UserLoginHasLoaded) ?  UserLogin.getAuthorizationHeader() : '';
    let url = _.result(model, 'url');

    if (url.startsWith('/')) {
        url = configuration.apiUrlBase + url;
    }

    options = _.extend(options, {
        url,
        headers: { 'Authorization': authorizationHeader }
    });

    return sync(method, model, options)
        // Using jQuery promise syntax. If using a Promise, this should be `.catch((error) => { ... })
        .fail((xhr, error, errorString) => {
            if (xhr.status === 401 && model !== UserLogin) {
                Application.prototype.onNavigate('login');
            } else {
                console.error(error, errorString);
                window.alert(xhr.responseText || errorString);
            }
        });
};
/* eslint-enable no-use-before-define */

const ConsumerSite = require('../site/consumerSite'),
    Dashboard = require('../dashboard/dashboard'),
    DeliverySite = require('../site/deliverySite'),
    Developer = require('../developer/developer'),
    Developers = require('../developers/developers'),
    Header = require('../header/header'),
    Login = require('../login/login'),
    Preview = require('../preview/preview'),
    Program = require('../program/program'),
    Programs = require('../programs/programs'),
    Recover = require('../recover/recover'),
    Reset = require('../reset/reset'),
    Sidebar = require('../sidebar/sidebar'),
    Sites = require('../sites/sites'),
    Survey = require('../survey/survey'),
    User = require('../user/user'),
    Users = require('../users/users'),
    //
    Enumerations = require('../../singletons/enumerations'),
    Router = require('../../singletons/router'),
    UserLogin = require('../../singletons/userLogin'),
    UserModel = require('../../singletons/user'),
    //
    templates = require('../../utilities/handlebars').templates,
    //
    routes = {
        consumer: { args: ['', '/:siteId'], view: ConsumerSite },
        dashboard: { view: Dashboard },
        developer: { args: ['', '/:developerId'], view: Developer },
        developers: { view: Developers },
        login: { view: Login },
        preview: { args: '/:programId', view: Preview },
        program: { args: ['', '/:programId', '/:programId/:tabName', '/:programId/:tabName/:developerId'], view: Program },
        programs: { view: Programs },
        recover: { view: Recover },
        reset: { args: '/:token', view: Reset },
        site: { args: ['', '/:siteId'], view: DeliverySite },
        sites: { view: Sites },
        survey: { args: ['/:surveyType/:organizationId/:programId', '/:surveyType/:organizationId/:programId/:template'], view: Survey },
        user: { args: [ '', '/:userId' ], view : User },
        users: { view: Users }
    };

UserLoginHasLoaded = true;

const ModalRegion = Marionette.Region.extend({
    el: '#modal-region',
    constructor: function (){
        _.bindAll(this);
        Marionette.Region.prototype.constructor.apply(this, arguments);
        this.on('show', this.showModal, this);
    },
    getEl: function (selector){
        const $el = $(selector);
        $el.on('hidden', this.close);
        return $el;
    },
    showModal: function (view){
        view.on('close', this.hideModal, this);
        this.$el.modal('show');
    },
    hideModal: function (){
        this.$el.modal('hide');
    }
});

const Application = Marionette.View.extend({
    attributes: {
        class: 'application unauthenticated basic-user'
    },
    events: {
        'click [data-action="navigate"]': 'onClickNavigate'
    },
    childViewEvents: {
        'modal:show': 'onShowModal',
        'modal:hide': 'onHideModal',
        'refresh': 'onRefresh',
        'navigate': 'onNavigate'
    },
    regions: {
        modal:  ModalRegion,
        header:  {el: 'div[data-region=header]',  replaceElement: true},
        sidebar: {el: 'div[data-region=sidebar]', replaceElement: true},
        main:    {el: 'main',                     replaceElement: false}
    },
    tagName: 'div',
    template: templates.application,
    initialize: function () {
        // Create these here, so they can start getting events even before they are rendered.
        this.header = new Header({ model: UserModel });
        this.sidebar = new Sidebar();

        this.listenTo(UserLogin, 'autoLogin', this.autoLogin);
        this.listenTo(UserLogin, 'loggedIn', this.loggedIn);
        this.listenTo(UserLogin, 'loggedOut', this.loggedOut);

        this.listenTo(Router, 'route', this.onNewRoute);

        Enumerations.fetch()
            .then(() => UserLogin.ready())
            // Start the router, but guard against going back to the previous page if we're entering on the 'login' page.
            .then(() => Router.start(routes, configuration.initialView, 'login'));
    },
    onAttach: function () {
        console.timeEnd('start');
    },
    onRender: function () {
        this.showChildView('header', this.header);
        this.showChildView('sidebar', this.sidebar);
    },
    onNewRoute: function (route, args) {
        if (routes[route]) {
            // console.log('hiya!');
            if (this.main) {
                this.main.destroy();
            }
            this.main = this.showChildView('main', new (routes[route].view)(...args));
        } else {
            console.error('application.navigate - unknown value:', route);
        }
    },
    onRefresh: function () {
        if (this.main.refresh) {
            this.main.refresh();
        }
    },
    onClickNavigate: function (event) {
        const action = event.currentTarget.dataset.action,
            value = event.currentTarget.dataset[action];

        this.onNavigate(value);

        return false;
    },
    onNavigate: function (route) {
        Router.navigate(route, {trigger: true});
    },
    autoLogin: function () {
        this.$el.removeClass('unauthenticated').addClass('authenticated');
        this.$el.removeClass('administrator-user basic-user').addClass(`${UserModel.getRole()}-user`);
    },
    loggedIn: function () {
        this.$el.removeClass('unauthenticated').addClass('authenticated');
        this.$el.removeClass('administrator-user basic-user').addClass(`${UserModel.getRole()}-user`);
        Backbone.history.history.back();
    },
    loggedOut: function () {
        this.$el.removeClass('authenticated').addClass('unauthenticated');
        this.$el.removeClass('administrator-user').addClass('basic-user');
        this.onNavigate('login');
    },
    onShowModal: function (view) {
        // Extend the 'logged in' classes to the Modal that we're about to show.
        const modalRegion = this.getRegion('modal');
        this.modalClasses = modalRegion.$el.prop('class');
        modalRegion.$el.addClass(this.$el.prop('class'));
        modalRegion.show(view);
    },
    onHideModal: function () {
        const modalRegion = this.getRegion('modal');
        modalRegion.hideModal();
        modalRegion.$el.prop('class', this.modalClasses);
    }
});

module.exports = Application;