'use strict';

const Marionette = require('backbone.marionette'),
    //
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'timeline',
        'data-name': 'timeline'
    },
    tagName: 'div',
    template: templates.timeline,
    initialize: function () {
        this.listenTo(this.collection, 'reset sync', this.render);
        this.collection.fetch();
    }
});
