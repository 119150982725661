'use strict';

const Sites = require('./sites');

module.exports = Sites.extend({
    //
    model: null,
    //
    url: null,      // Abstract collection
    //
    programId: 0,
    //
    initialize: function (options) {
        this.programId = options.programId;
        this.on('reset', () => {
            this.offset = 0;
        });
    },
    getProgramId: function () {
        return this.programId;
    },
    setProgramId: function (programId) {
        this.programId = programId;
    }
});
