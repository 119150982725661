'use strict';

const Marionette = require('backbone.marionette'),
    //
    UserLogin = require('../../singletons/userLogin'),
    //
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'header',
        'data-name': 'header'
    },
    ui: {
        headerOptions: '.js-header-options',
        headerLogout: '.js-header-logout'
    },
    events: {
        'click @ui.headerOptions': 'onHeaderOptionsClicked',
        'click @ui.headerLogout': 'onHeaderLogoutClicked'
    },
    tagName: 'div',
    template: templates.header,
    initialize: function () {
        this.listenTo(this.model, 'change', this.render);
    },
    onRender: function () {
        this.$('[sidebar-toggle]').click(() => {
            this.$el.parent().toggleClass('sidebar-collapsed');
            return false;
        });

    },
    onHeaderOptionsClicked: function () {
        this.trigger('navigate', `user/${this.model.id}`);
        return false;
    },
    onHeaderLogoutClicked: function () {
        UserLogin.loggedOut();
    }
});
