'use strict';

const Backbone = require('backbone');

const Questions = Backbone.Collection.extend({
    url: function () {
        return `/questions/type/${this.programDataSetTypeId}`;
    },
    getProgramDataSetTypeId: function () {
        return this.programDataSetTypeId;
    },
    setProgramDataSetTypeId: function (programDataSetTypeId) {
        this.programDataSetTypeId = programDataSetTypeId;
    }
});

module.exports = Questions;
