'use strict';

const Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    //
    templates = require('../../../utilities/handlebars').templates;

const AnnualUpdate = Backbone.Model.extend({
    urlRoot: '/program/annual'
});

module.exports = Marionette.View.extend({
    attributes: {
        class: 'program-status',
        'data-name': 'program-status'
    },
    ui: {
        form: 'form',
        checkbox: 'form input[type="checkbox"]',
        submit: '.js-submit-button',
        update: '.js-start-update'
    },
    events: {
        'click @ui.checkbox': 'onCheckbox',
        'click @ui.submit': 'onSubmit',
        'click @ui.update': 'onUpdate'
    },
    tagName: 'div',
    template: templates.programStatus,
    //
    onCheckbox: function (event) {
        const $checkbox = this.$(event.currentTarget);
        const id = $checkbox.data('program-status-id');

        this.collection.get(id).setCompleted($checkbox.is(':checked'));
    },
    onUpdate: function () {
        const annualUpdate = new AnnualUpdate({ id: this.collection.programId });
        annualUpdate.save();
        // Save the collection, even if nothing has changed from the client, so we can fetch the new statuses.
        return this.onSubmit();
    },
    onSubmit: function () {
        this.collection.save();
        this.trigger('modal:hide');
        return false;
    }
});
