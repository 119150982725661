'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    //
    Table = require('../../table/table'),
    //
    templates = require('../../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'programs-list programs-list-sort-program sort-up card-list',
        'data-name': 'programs-list'
    },
    ui: {
        sort: '[data-action="sort"]'
    },
    events: {
        'change select': 'onSelectChange',
        'click @ui.sort': 'onSort',
        'scroll': 'onScroll'
    },
    regions: {
        programsTable: {el: 'div[data-region=programs-table]', replaceElement: true}
    },
    tagName: 'div',
    template: templates.programsList,
    //
    initialize: function (options) {
        this.collection = options.collection;
        this.rowTemplate = options.rowTemplate;
        this.collection.fetch();
    },
    onSelectChange: function (event) {
        const $select = this.$(event.currentTarget);
        const $spinner = $select.prev();
        const id = $select.parents('[data-id]').data('id');
        const model = this.collection.get(id);

        $select.prop('disabled', true);
        $spinner.addClass('spinner');

        model.set('programStatusId', event.currentTarget.value);
        model.save()
            .always(() => {
                $spinner.removeClass('spinner');
                $select.prop('disabled', false);
            });

        return false;
    },
    onRender: function () {
        this.programs = this.showChildView('programsTable', new Table({
            collection: this.collection,
            rowTemplate: this.rowTemplate
        }));
    },
    onSort: function (event) {
        const action = event.currentTarget.dataset.action,
            value = event.currentTarget.dataset[action];

        const sortColumns = this.collection.getSortColumns();
        let sortDirection = this.collection.getSortDirection();
        let sortClass = this.getSortClass(sortColumns, sortDirection);
        this.$el.removeClass(sortClass);

        if (sortColumns === value) {
            // Toggle the sort direction.
            sortDirection = (sortDirection === 'ASC') ? 'DESC' : 'ASC';
        } else {
            sortDirection = 'DESC';
        }

        sortClass = this.getSortClass(value, sortDirection);
        this.$el.addClass(sortClass);

        this.collection.setSortDirection(sortDirection);
        this.collection.setSortColumns(value);
        this.trigger('refresh.programs');

        return false;
    },
    getSortClass: function (sortColumns, sortDirection) {
        const sortClass = this.$(`[data-sort="${sortColumns}"]`).data('sort-class');
        const sortUpDown = (sortDirection === 'ASC') ? 'sort-up' : 'sort-down';

        return `${sortClass} ${sortUpDown}`;
    }
});
