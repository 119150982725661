'use strict';

const Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    //
    SitesHeader = require('./sitesHeader/sitesHeader'),
    SitesList = require('./sitesList/sitesList'),
    DeliverySites = require('../../collections/deliverySites'),
    ConsumerSites = require('../../collections/consumerSites'),
    //
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'sites scroll-y',
        'data-name': 'sites'
    },
    childViewEvents: {
        'refresh.sites': 'refresh'
    },
    events: {
        'scroll': 'onScroll'
    },
    regions: {
        sitesHeader: {el: 'div[data-region=sites-header]', replaceElement: true},
        sitesList: {el: 'div[data-region=sites-list]', replaceElement: true}
    },
    tagName: 'div',
    template: templates.sites,
    //
    initialize: function () {
        this.currentScrollHeight = 0;
        this.model = new Backbone.Model({
            switch: false,
            matchingText: ''
        });
        this.collections = {
            consumerSites: new ConsumerSites(),
            deliverySites: new DeliverySites()
        };
        this.collection = this.collections.deliverySites;
    },
    onScroll: function () {
        const scrollHeight = this.el.scrollHeight;
        const scrollPos = Math.floor(this.el.clientHeight + this.el.scrollTop);
        const isBottom = scrollHeight - 300 < scrollPos;

        // console.log('this.currentScrollHeight=', this.currentScrollHeight, 'scrollHeight=', scrollHeight, 'scrollPos=', scrollPos, 'isBottom=', isBottom);

        if (isBottom && this.currentScrollHeight < scrollHeight) {
            // console.log('onScroll: load more');
            this.collection.fetch({remove: false});
            this.currentScrollHeight = scrollHeight;
        }

        // This is an alternative scroll handler, that will continually try to fetch more rows. It has no concept of "having reached the bottom".
        //         const scrollY = this.el.scrollHeight - this.el.scrollTop;
        //         const offsetHeight = this.el.offsetHeight;
        //         const offset = scrollY - offsetHeight;
        //
        //         // console.log('scrollY=', scrollY, 'offsetHeight=', offsetHeight, 'offset=', offset);
        //
        //         if (offset <= 0) {
        //             // console.log('onScroll: load more');
        //             this.collection.fetch({remove: false});
        //         }
    },
    onRender: function () {
        this.sitesHeader = this.showChildView('sitesHeader', new SitesHeader({ model: this.model }));
        this.sitesList = this.showChildView('sitesList', new SitesList({
            collection: this.collection,
            rowTemplate: (this.model.get('switch')) ? templates.consumerSitesListRow : templates.deliverySitesListRow
        }));
    },
    refresh: function () {
        this.currentScrollHeight = 0;
        this.collection = (this.model.get('switch')) ? this.collections.consumerSites : this.collections.deliverySites;
        this.collection.reset();
        this.collection.setMatchingText(this.model.get('matchingText'));
        this.render();
    }
});
