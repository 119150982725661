'use strict';

const Backbone = require('backbone'),
    Developer = require('../models/developer');

module.exports = Backbone.Collection.extend({
    model: Developer,
    //
    url: '/developers',
    //
    offset: 0,
    count: 35,
    sortColumns: 'name',
    sortDirection: 'ASC',
    //
    initialize: function () {
        this.on('reset', () => {
            this.offset = 0;
        });
    },
    sync: function (method, model, options) {
        options = options || {};
        options.data = {
            matchingText: this.matchingText,
            sortColumns: this.sortColumns,
            sortDirection: this.sortDirection,
            offset: this.offset,
            count: this.count
        };
        return Backbone.sync.apply(this, [method, model, options]);
    },
    parse: function (results) {
        this.offset += results && results.length || 0;
        return results;
    },
    getMatchingText: function () {
        return this.matchingText;
    },
    setMatchingText: function (matchingText) {
        this.matchingText = matchingText;
    },
    getSortColumns: function () {
        return this.sortColumns;
    },
    setSortColumns: function (sortColumns) {
        this.sortColumns = sortColumns;
    },
    getSortDirection: function () {
        return this.sortDirection;
    },
    setSortDirection: function (sortDirection) {
        this.sortDirection = sortDirection;
    }
});
