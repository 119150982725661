'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    //
    DevelopersHeader = require('./developersHeader/developersHeader'),
    DevelopersList = require('./developersList/developersList'),
    Developers = require('../../collections/developers'),
    //
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'developers scroll-y',
        'data-name': 'developers'
    },
    childViewEvents: {
        'refresh.developers': 'refresh'
    },
    events: {
        'scroll': 'onScroll'
    },
    regions: {
        developersHeader: {el: 'div[data-region=developers-header]', replaceElement: true},
        developersList: {el: 'div[data-region=developers-list]', replaceElement: true}
    },
    tagName: 'div',
    template: templates.developers,
    //
    initialize: function () {
        this.currentScrollHeight = 0;
        this.collections = {
            developers: new Developers()
        };
    },
    onScroll: function () {
        const scrollHeight = this.el.scrollHeight;
        const scrollPos = Math.floor(this.el.clientHeight + this.el.scrollTop);
        const isBottom = scrollHeight - 300 < scrollPos;

        // console.log('this.currentScrollHeight=', this.currentScrollHeight, 'scrollHeight=', scrollHeight, 'scrollPos=', scrollPos, 'isBottom=', isBottom);

        if (isBottom && this.currentScrollHeight < scrollHeight) {
            // console.log('onScroll: load more');
            this.collections.developers.fetch({remove: false});
            this.currentScrollHeight = scrollHeight;
        }

        // This is an alternative scroll handler, that will continually try to fetch more rows. It has no concept of "having reached the bottom".
        //         const scrollY = this.el.scrollHeight - this.el.scrollTop;
        //         const offsetHeight = this.el.offsetHeight;
        //         const offset = scrollY - offsetHeight;
        //
        //         // console.log('scrollY=', scrollY, 'offsetHeight=', offsetHeight, 'offset=', offset);
        //
        //         if (offset <= 0) {
        //             // console.log('onScroll: load more');
        //             this.collections.developers.fetch({remove: false});
        //         }
    },
    onRender: function () {
        this.developersHeader = this.showChildView('developersHeader', new DevelopersHeader({collection: this.collections.developers}));
        this.developersList = this.showChildView('developersList', new DevelopersList({
            collection: this.collections.developers,
            rowTemplate: templates.developersListRow
        }));
    },
    refresh: function () {
        this.currentScrollHeight = 0;
        this.collections.developers.reset();
        this.collections.developers.fetch();
    }
});
