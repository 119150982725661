'use strict';

const Backbone = require('backbone');

const Responses = Backbone.Collection.extend({
    url: '/responses',
    offset: 0,
    count: 35,
    initialize: function () {
        this.on('reset', () => {
            this.offset = 0;
        });
    },
    sync: function (method, model, options) {
        options = options || {};
        options.data = {
            offset: this.offset,
            count: this.count
        };
        return Backbone.sync.apply(this, [method, model, options]);
    },
    parse: function (results) {
        this.offset += results && results.length || 0;
        return results;
    },
    getOffset: function () {
        return this.offset;
    },
    setOffset: function (offset) {
        this.offset = offset;
    },
    getCount: function () {
        return this.count;
    },
    setCount: function (count) {
        this.count = count;
    }
});


module.exports = Responses;