'use strict';

// jshint ignore: start
/* eslint-disable */

const Marionette = require('backbone.marionette'),
    //
    ProgramDetails = require('../../models/programDetails'),
    ProgramStatus = require('../../collections/programStatus'),
    StatusModel = require('../../models/status'),
    //
    PreviewDetailsView = require('./previewDetails'),
    //
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'preview',
        'data-name': 'preview'
    },
    ui: {
        reviewed: '.js-reviewed:not(.disabled)'
    },
    events: {
        'click @ui.reviewed': 'onReviewed'
    },
    regions: {
        previewDetails:  {el: 'div[data-region=preview-details]',  replaceElement: true}
    },
    tagName: 'div',
    template: templates.preview,
    //
    initialize: function (programId) {
        const programDetailsModel = new ProgramDetails({ id: programId });
        programDetailsModel.fetch()
            .then(() => {
                this.model = programDetailsModel;
                this.render();
            });
    },
    onReviewed: function (event) {
        const $elem = this.$(event.currentTarget);

        const developerStatusReviewed = new StatusModel({
            statusName: 'developerDataReview'
        });
        developerStatusReviewed.setCompleted(true);

        const programId = this.model.get('program').id;
        const programStatus = new ProgramStatus([ developerStatusReviewed ]);
        programStatus.setProgramId(programId);

        programStatus.save();

        $elem.closest('.js-reviewed').addClass('disabled');
    },
    onRender: function () {
        if (this.model) {
            this.showChildView('previewDetails', new PreviewDetailsView({ model: this.model }));
        }
    }
});
