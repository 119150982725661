'use strict';

const Backbone  = require('backbone'),
    Marionette = require('backbone.marionette'),
    handlebars = require('handlebars'),
    Radio = require('backbone.radio'),
    //
    configuration = require('../../../utilities/configuration'),
    //
    ProgramEmailView = require('../programEmail/programEmail');

const DeveloperTemplateModel = Backbone.Model.extend({
    urlRoot: function () {
        return `/template/developer/${this.get('organizationId')}/${this.get('programId')}`;
    }
});

const DeveloperEmailModel = Backbone.Model.extend({
    urlRoot: function () {
        return `/message/developer/${this.get('organizationId')}/${this.get('programId')}`;
    }
});

const DeveloperSendEmailModel = Backbone.Model.extend({
    urlRoot: function () {
        return `/message/developer/send/${this.get('organizationId')}/${this.get('programId')}`;
    }
});

const SiteTemplateModel = Backbone.Model.extend({
    urlRoot: function () {
        return `/template/site/${this.get('organizationId')}/${this.get('programId')}`;
    }
});

const SiteEmailModel = Backbone.Model.extend({
    urlRoot: function () {
        return `/message/site/${this.get('organizationId')}/${this.get('programId')}`;
    }
});

const SiteSendEmailModel = Backbone.Model.extend({
    urlRoot: function () {
        return `/message/site/send/${this.get('organizationId')}/${this.get('programId')}`;
    }
});

const PublishSiteModel = Backbone.Model.extend({
    urlRoot: function () {
        return `/consumer/publish/${this.get('organizationId')}/${this.get('programId')}`;
    }
});

module.exports = Marionette.View.extend({
    attributes: {
        class: 'communications',
        'data-name': 'communications'
    },
    ui: {
        communicationsStatus: '[data-communications-status-name]',
        communicationsStatusButton: '[data-communications-status-name] button',
        communicationsStatusCheckbox: '[data-communications-status-name] input[type]',
        communicationsStatusDate: '.js-status-date',
        publishSite: '.js-publish-site',
        editEmail: '.js-edit-email',
        viewEmail: '.js-view-email',
        resendEmail: '.js-resend-email',
        sendEmail: '.js-send-email'
    },
    events: {
        // 'click @ui.communicationsStatusButton': 'onCommunicationsStatus',
        'click @ui.communicationsStatusCheckbox': 'onCommunicationsStatus',
        'click @ui.publishSite': 'onPublishSite',
        'click @ui.editEmail': 'onEditEmail',
        'click @ui.viewEmail': 'onEditEmail',   // For now, change this from 'onViewEmail' to allow editing of a sent email.
        'click @ui.resendEmail': 'onResendEmail',
        'click @ui.sendEmail': 'onSendEmail'
    },
    tagName: 'div',
    getTemplate: function () {
        return this.communicationsTemplate;
    },
    //
    initialize: function (options) {
        const organizationId = options.organizationId,
            programId = options.programModel.id,
            modelAttributes = {
                clientUrlBase: configuration.clientUrlBase,
                organizationId,
                programId
            };
        this.model = new Backbone.Model(modelAttributes);
        this.organizationType = options.organizationType;
        this.emailModels = {
            template: (this.organizationType === 'site') ? new SiteTemplateModel(modelAttributes) : new DeveloperTemplateModel(modelAttributes),
            email: (this.organizationType === 'site') ? new SiteEmailModel(modelAttributes) : new DeveloperEmailModel(modelAttributes),
            send: (this.organizationType === 'site') ? new SiteSendEmailModel(modelAttributes) : new DeveloperSendEmailModel(modelAttributes)
        };

        this.publishModel = new PublishSiteModel({ organizationId, programId });

        this.communicationsTemplate = options.template;
        this.programModel = options.programModel;
        this.listenTo(this.collection, 'sync', this.render);
    },
    onRender: function () {
        const template = handlebars.compile('{{dateFormat completedWhen "M/DD/YY"}}');
        this.collection.forEach((programStatusModel) => {
            const status = programStatusModel.get('status') || '';
            const statusName = programStatusModel.get('statusName');
            if (statusName) {
                const $elem = this.$(`[data-communications-status-name="${statusName}"]`);
                const completed = status.includes('completed');
                const pending = status === 'pending';
                const overdue = status === 'overdue';
                $elem.toggleClass('completed', completed);
                $elem.toggleClass('pending', !completed);
                $elem.toggleClass('overdue', overdue);
                $elem.find('input[type]').prop('checked', completed);

                if (!pending) {
                    const date = template(programStatusModel.attributes);
                    $elem.find(this.ui.communicationsStatusDate).text(date);
                }
            }
        });
    },
    findTarget: function (target) {
        const $elem = this.$(target);
        const statusName = $elem.closest(this.ui.communicationsStatus).data('communications-status-name');
        const statusModel = this.collection.filter({statusName})[0];

        return [ statusModel, $elem ];
    },
    onCommunicationsStatus: function (event) {
        const [ statusModel, $elem ] = this.findTarget(event.currentTarget);

        // console.log(statusModel.get('statusName'), $elem.is(':checked'));

        switch ($elem.attr('type')) {
        case 'button':
            // Set model immediately to "completed"
            statusModel.setCompleted(true);
            break;
        case 'radio':
            this.$(`[name="${$elem.prop('name')}"]`).each((idx, e) => {
                const [ radioStatusModel, $e ] = this.findTarget(e);
                if (radioStatusModel) {
                    // Skip the N/A button for a tri-state switch.
                    radioStatusModel.setCompleted($e.is(':checked'));
                }
            });
            break;
        case 'checkbox':
            // Set model to either 'completed' or 'pending', depending on whether this element is checked.
            statusModel.setCompleted($elem.is(':checked'));
            break;
        }
        this.collection.save()
            .then(() => this.collection.fetch())
            .then(() => this.render());
    },
    onEditEmail: function (event) {
        const channel = Radio.channel('programEmail');
        channel.reply('sendEmail', () => {
            this.onSendEmail(event);
        });
        const [ statusModel, $elem ] = this.findTarget(event.currentTarget);
        const title = $elem.closest('[data-communications-status-name]').find('.js-editor-title').text();
        this.emailModels.email.set({
            id: statusModel.id,
            title,
            viewOnly: false
        });
        this.trigger('modal:show', new ProgramEmailView({
            model: this.emailModels.email,
            templateModel: this.emailModels.template
        }));
        return false;
    },
    onViewEmail: function (event) {
        const [ statusModel, $elem ] = this.findTarget(event.currentTarget);
        const title = $elem.closest('[data-communications-status-name]').find('.js-editor-title').text();
        this.emailModels.email.set({
            id: statusModel.id,
            title,
            viewOnly: true
        });
        this.trigger('modal:show', new ProgramEmailView({
            model: this.emailModels.email,
            templateModel: this.emailModels.template
        }));
        return false;
    },
    onResendEmail: function (event) {
        this.onSendEmail(event);
    },
    onSendEmail: function (event) {
        const [ statusModel ] = this.findTarget(event.currentTarget);
        this.emailModels.send.set('id', statusModel.id);
        this.emailModels.send.save();
        this.onCommunicationsStatus(event);
    },
    onPublishSite: function () {
        this.publishModel.save();
    }
});
