'use strict';

const Sites = require('./sites'),
    ConsumerSite = require('../models/consumerSite');

const DashboardConsumerSites = Sites.extend({
    //
    model: ConsumerSite,
    //
    url: '/consumers/dashboard'
});

module.exports = DashboardConsumerSites;