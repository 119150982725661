'use strict';

const Backbone = require('backbone'),
    ProgramQuestion = require('../models/programQuestion');

module.exports = Backbone.Collection.extend({
    model: ProgramQuestion,
    //
    url: function () {
        return '/program/questions/' + this.programId;
    },
    //
    offset: 0,
    count: Number.MAX_SAFE_INTEGER,
    programId: 0,
    initialize: function (options) {
        this.programId = options.programId;
    },
    getProgramId: function () {
        return this.programId;
    },
    setProgramId: function (programId) {
        this.programId = programId;
    },
    sync: function (method, model, options) {
        options = options || {};
        options.data = {
            offset: this.offset,
            count: this.count
        };
        return Backbone.sync.apply(this, [method, model, options]);
    },
    save: function (options) {
        return Backbone.sync('update', this, options)
            .then(() => this.trigger('saved'));
    }
});
