'use strict';

const SitePrograms = require('./sitePrograms');

module.exports = SitePrograms.extend({
    //
    url: function () {
        return '/programs/consumer/' + this.siteId;
    }
});
