'use strict';

require('../vendor/backbone-composite-keys/backbone-composite-keys');

const Backbone = require('backbone');

const Answer = Backbone.Model.extend({
    urlRoot: function () {
        return `/answer/${this.surveyType}/${this.programId}`;
    },
    idAttribute: [ 'questionId', 'responseId' ],
    getSurveyType: function () {
        return this.surveyType;
    },
    setSurveyType: function (surveyType) {
        this.surveyType = surveyType;
    },
    getProgramId: function () {
        return this.programId;
    },
    setProgramId: function (programId) {
        this.programId = programId;
    }
});

module.exports = Answer;