'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    //
    templates = require('../../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'content-header sites-header sticky-top view-header',
        'data-name': 'sites-header'
    },
    ui: {
        form: 'form',
        filter: '.js-filter',
        search: '.js-search',
        switch: '#consumer-site-switch'
    },
    events: {
        'change @ui.switch': 'onSwitch',
        'click @ui.search': 'onSubmit',
        'submit': 'onSubmit'
    },
    tagName: 'div',
    template: templates.sitesHeader,
    //
    initialize: function (options) {
        this.model = options.model;
    },
    onRender: function () {
        this.ui.switch.prop('checked', this.model.get('switch'));
    },
    onSwitch: function () {
        this.model.set('switch', this.ui.switch.prop('checked'));
        this.trigger('refresh.sites');

        return false;
    },
    onSubmit: function () {
        const filter = this.ui.filter;
        this.model.set('matchingText', filter.val());
        this.trigger('refresh.sites');
        
        return false;
    }
});
