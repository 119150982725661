'use strict';

const Marionette = require('backbone.marionette'),
    Radio = require('backbone.radio'),
    // $ = require('jquery'),
    _ = require('lodash'),
    //
    Enumerations = require('../../singletons/enumerations'),
    //
    DevelopersList = require('../developers/developersList/developersList'),
    SitesList = require('../sites/sitesList/sitesList'),
    User = require('../../models/user'),
    UserDevelopers = require('../../collections/userDevelopers'),
    UserDeliverySites = require('../../collections/userDeliverySites'),
    UserConsumerSites = require('../../collections/userConsumerSites'),
    //
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'user',
        'data-name': 'user'
    },
    ui: {
        form: 'form',
        saveEdit: '.js-save-edit-button',
        delete: '.js-delete-button',
        cancel: '.js-cancel-button',
        submit: '.js-submit-button'
    },
    events: {
        'click @ui.saveEdit': 'onSubmit',
        'click @ui.delete': 'onDelete',
        'click @ui.cancel': 'onCancel',
        'click @ui.submit': 'onSubmit'
    },
    childViewEvents: {
        'refresh.developers': 'refreshDevelopers',
        'refresh.sites': 'refreshSites'
    },
    regions: {
        userDevelopers: {el: 'div[data-region=user-developers-list]', replaceElement: true},
        userDeliverySites: {el: 'div[data-region=user-delivery-sites-list]', replaceElement: true},
        userConsumerSites: {el: 'div[data-region=user-consumer-sites-list]', replaceElement: true}
    },
    tagName: 'div',
    getTemplate: function () {
        return (this.isModal) ? templates.userModal : templates.user;
    },
    //
    initialize: function (userId, isModal) {
        // We can enter here from two possible routes: '/user' and '/user/:userId'
        // '/user' is the easy case. We are creating a new user and all the form fields can be blank.
        // '/user/:userId' means we are editing an existing user. We fetch the data for this user and fill in the form.
        if (userId) {
            this.$el.addClass('is-existing');
            this.model = new User({ id: userId });
            this.model.fetch()
                // All fields that are *required* in the PUT really ought to be returned by the GET.
                .then(() => this.setFieldsFromModel());
            this.collections = {
                developers: new UserDevelopers({ userId: userId }),
                developerSites: new UserDeliverySites({ userId: userId }),
                consumerSites: new UserConsumerSites({ userId: userId })
            };
        } else {
            this.$el.addClass('is-new');
            this.model = new User();
        }
        this.isModal = isModal;
    },
    setFieldsFromModel: function () {
        this.ui.form.find(':input').each((ix, elem) => this.$(elem).val(this.model.get((this.$(elem).attr('name')))));
    },
    onRender: function () {
        if (this.model.id) {
            this.collections.developers.reset();
            this.developersList = this.showChildView('userDevelopers', new DevelopersList({
                collection: this.collections.developers,
                rowTemplate: templates.userDevelopersListRow
            }));

            this.collections.developerSites.reset();
            this.siteList = this.showChildView('userDeliverySites', new SitesList({
                collection: this.collections.developerSites,
                rowTemplate: templates.userDeliverySitesListRow
            }));

            this.collections.consumerSites.reset();
            this.consumerSitesList = this.showChildView('userConsumerSites', new SitesList({
                collection: this.collections.consumerSites,
                rowTemplate: templates.userConsumerSitesListRow
            }));
        }
    },
    onDelete: function () {
        this.model.destroy()
            .always(() => this.trigger('navigate', 'users'));

        return false;
    },
    onCancel: function () {
        if (this.isModal) {
            this.trigger('modal:hide');
        } else {
            this.trigger('navigate', 'users');
        }
        return false;
    },
    onSubmit: function () {
        const isNewUser = !this.model.id;
        const data = _.mapValues(_.keyBy(this.ui.form.serializeArray(), 'name'), 'value');
        this.model.save(data)
            .then((result) => {
                if (isNewUser && String(Enumerations.get('userRoleByName').administrator.id) === String(this.model.get('roleId'))) {
                    window.alert(`An email has been sent to ${result.email} to finalize the User and to set a password.`);
                }
            })
            .then(() => {
                if (this.isModal) {
                    const channel = Radio.channel('user');
                    channel.request('user', this.model.id, [this.model.get('firstName'), this.model.get('lastName')].join(' '), this.model.get('email'));
                    this.trigger('modal:hide');
                } else {
                    this.$el.removeClass('is-new').addClass('is-existing');
                    this.trigger('navigate', `user/${this.model.id}`);
                }
            });

        return false;
    },
    refreshDevelopers: function () {
        this.collections.developers.reset();
        this.collections.developers.fetch();
    },
    refreshSites: function () {
        this.collections.developerSites.reset();
        this.collections.developerSites.fetch();

        this.collections.consumerSites.reset();
        this.collections.consumerSites.fetch();
    }
});
