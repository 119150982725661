'use strict';

const Marionette = require('backbone.marionette'),
    //
    ProgramStatus = require ('../../../collections/programStatus'),
    ProgramDeveloperStatus = require('../../../collections/programDeveloperStatus'),
    //
    User = require('../../../singletons/user'),
    //
    TimelineView = require('../../timeline/timeline'),
    //
    templates = require('../../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'developer-timelines',
        'data-name': 'developer-timelines'
    },
    ui: {
        approveProgram: '.js-approve-program'
    },
    events: {
        'click @ui.approveProgram': 'onApproveProgram'
    },
    tagName: 'div',
    getTemplate: function () {
        return templates.developerStatusDashboard[User.getRole()];
    },
    initialize: function () {
        this.collection.fetch()
            .then(() => this.render());
    },
    onRender: function () {
        this.collection.each((model) => {
            const developerTimelineName = `developer-timeline-${model.id}`;
            const programDeveloperStatus = new ProgramDeveloperStatus({ programId: model.id });
            this.listenTo(programDeveloperStatus, 'sync', this.setStatus);

            this.addRegion(developerTimelineName, `div[data-region=${developerTimelineName}]`);
            this.showChildView(developerTimelineName, new TimelineView({
                collection: programDeveloperStatus
            }));

            model.programDeveloperStatus = programDeveloperStatus;
        });
    },
    setStatus: function (programDeveloperStatus) {
        const $card = this.$(`[data-region="developer-timeline-${programDeveloperStatus.programId}"`).closest('.card');
        const developerSurveyComplete = programDeveloperStatus.findWhere({ statusName: 'developerSurveyComplete' });
        const developerMaterialsReceived = programDeveloperStatus.findWhere({ statusName: 'developerMaterialsReceived' });
        const developerSiteListReceived = programDeveloperStatus.findWhere({ statusName: 'developerSiteListReceived' });
        const developerDataCleaned = programDeveloperStatus.findWhere({ statusName: 'developerDataCleaned' });
        const developerDataReview = programDeveloperStatus.findWhere({ statusName: 'developerDataReview' });
        const developerDataApproved = programDeveloperStatus.findWhere({ statusName: 'developerDataApproved' });

        if (developerSurveyComplete) {
            $card.toggleClass('needs-survey', !developerSurveyComplete.get('status').startsWith('completed'));
        }
        if (developerMaterialsReceived) {
            $card.toggleClass('needs-materials', !developerMaterialsReceived.get('status').startsWith('completed'));
        }
        if (developerSiteListReceived) {
            $card.toggleClass('needs-sites', !developerSiteListReceived.get('status').startsWith('completed'));
        }

        const developerDataCleanedStatus = developerDataCleaned && developerDataCleaned.get('status');
        const developerDataReviewStatus = developerDataReview && developerDataReview.get('status');
        const developerDataApprovedStatus = developerDataApproved && developerDataApproved.get('status');

        $card.toggleClass('needs-review', developerDataCleanedStatus.startsWith('completed') && !developerDataReviewStatus.startsWith('completed'));
        $card.toggleClass('needs-approval', developerDataReviewStatus.startsWith('completed') && !developerDataApprovedStatus.startsWith('completed'));
    },
    onApproveProgram: function (event) {
        // console.log('onApproveProgram', event);
        const $card = this.$(event.currentTarget).closest('.needs-approval');
        const programId = $card.data('program-id');

        const programDeveloperStatus = this.collection.map((model) => model.programDeveloperStatus).filter((model) => model.programId === programId)[0];

        if (programDeveloperStatus) {
            const programDeveloperStatusModels = programDeveloperStatus.toJSON();
            const programStatus = new ProgramStatus(programDeveloperStatusModels);
            programStatus.setProgramId(programDeveloperStatus.getProgramId());

            const developerDataApproved = programStatus.filter({ statusName: 'developerDataApproved' })[0];
            developerDataApproved.setCompleted(true);
            programStatus.save()
                .then(() => this.render());
        }
    }
});
