'use strict';

const Backbone = require('backbone');

module.exports = Backbone.Collection.extend({
    model: Backbone.Model,
    //
    url: function () {
        return '/program/site/status/' + this.programId;
    },
    //
    programId: 0,
    initialize: function (options) {
        this.programId = options.programId;
    },
    getProgramId: function () {
        return this.programId;
    },
    setProgramId: function (programId) {
        this.programId = programId;
    }
});
