'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    // _ = require('lodash'),
    //
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'table-responsive'
    },
    tagName: 'div',
    template: templates.table,
    //
    initialize: function () {
        this.rowTemplate = this.options.rowTemplate;
        this.listenTo(this.collection, 'reset sync', this.render);
    },
    templateContext: function () {
        const o = {
            title: this.options.title || '',
            rows: this.collection.toJSON(),
            template: this.rowTemplate,
            class: this.options.class || '',
            zeroRecords: !this.collection.length
        };
        return o;
    }
});
