'use strict';

const Backbone = require('backbone'),
    StatusModel = require('../models/status');

const SiteStatus = Backbone.Collection.extend({
    model: StatusModel,
    //
    url: function () {
        return '/site/status/' + this.programId + '/' + this.siteId;
    },
    //
    siteId: 0,
    includeOptional: false,
    initialize: function (models, options) {
        this.programId = options.programId;
        this.siteId = options.siteId;
    },
    getProgramId: function () {
        return this.programId;
    },
    setProgramId: function (programId) {
        this.programId = programId;
    },
    getSiteId: function () {
        return this.siteId;
    },
    setSiteId: function (siteId) {
        this.siteId = siteId;
    },
    getIncludeOptional: function () {
        return this.includeOptional;
    },
    setIncludeOptional: function (includeOptional) {
        this.includeOptional = includeOptional;
    },
    sync: function (method, model, options) {
        options = options || {};
        options.data = {
            includeOptional: this.includeOptional
        };
        return Backbone.sync.apply(this, [method, model, options]);
    },
    save: function (options) {
        return Backbone.sync('update', this, options);
    }
});

module.exports = SiteStatus;