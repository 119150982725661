'use strict';

const Sites = require('./sites'),
    DeliverySite = require('../models/deliverySite');

module.exports = Sites.extend({
    //
    model: DeliverySite,
    //
    url: '/sites'
});
