'use strict';

const Sites = require('./sites'),
    ConsumerSite = require('../models/consumerSite');

module.exports = Sites.extend({
    //
    model: ConsumerSite,
    //
    url: '/consumers'
});
