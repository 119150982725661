'use strict';

const Marionette = require('backbone.marionette'),
    Radio = require('backbone.radio'),
    autocomplete = require('autocompleter'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    SiteUsers = require('../../collections/siteUsers'),
    User = require('../../models/user'),
    Users = require('../../collections/users'),
    //
    OrganizationUsersView = require('../organizationUsers/organizationUsers'),
    ProgramsList = require('../programs/programsList/programsList'),
    UserView = require('../user/user'),
    //
    templates = require('../../utilities/handlebars').templates;

const SiteUserModel = User.extend({
    idAttribute: 'userId',
    urlRoot: function () {
        return `/site/user/${this.get('siteId')}`;
    },
    setUserId: function (userId) {
        this.set('userId', userId);
    }
});

module.exports = Marionette.View.extend({
    attributes: {
        class: 'site',
        'data-name': 'site'
    },
    ui: {
        createUser: '.js-create-user',
        siteName: '.js-site-name',
        form: 'form',
        saveEdit: '.js-save-edit-button',
        delete: '.js-delete-button',
        cancel: '.js-cancel-button',
        submit: '.js-submit-button',
        //
        programsCount: '.js-programs-count',
        adminEmail: '[name="email"]',
        adminUserId: '[name="adminUserId"]',
        adminUserName: '[name="fullName"]'
    },
    events: {
        'click @ui.createUser': 'onCreateUser',
        'click @ui.saveEdit': 'onSubmit',
        'click @ui.delete': 'onDelete',
        'click @ui.cancel': 'onCancel',
        'click @ui.submit': 'onSubmit'
    },
    childViewEvents: {
        'refresh.programs': 'refreshPrograms'
    },
    childViewTriggers: {
        'modal:show': 'modal:show',
        'modal:hide': 'modal:hide'
    },
    regions: {
        sitePrograms: {el: 'div[data-region=site-programs-list]', replaceElement: true},
        siteUsers: {el: 'div[data-region=site-users]', replaceElement: true}
    },
    tagName: 'div',
    getTemplate: function () {
        return (this.isModal) ? templates.siteModal : templates.site;
    },
    //
    initialize: function (siteId, options) {
        // We can enter here from two possible routes: '/site' and '/site/:siteId'
        // '/site' is the easy case. We are creating a new site and all the form fields can be blank.
        // '/site/:siteId' means we are editing an existing site. We fetch the data for this site and fill in the form.
        if (siteId) {
            this.$el.addClass('is-existing');
            this.model = new options.siteFactory({ id: siteId });
            this.model.fetch()
                .then(() => this.render())
                // All fields that are *required* in the PUT really ought to be returned by the GET.
                .then(() => this.setFieldsFromModel());
            this.collections = {
                programs: options.programs,
                users: new SiteUsers({ siteId: siteId })
            };

            this.listenTo(this.collections.programs, 'sync', () => {
                this.ui.programsCount.text(this.collections.programs.length);
            });
        } else {
            this.$el.addClass('is-new');
            this.model = new options.siteFactory();
        }
        this.isModal = options.isModal;
        this.rowTemplate = options.rowTemplate || templates.siteProgramsListRow;
        this.title = options.title || 'Site';
    },
    setFieldsFromModel: function () {
        this.ui.form.find(':input').each((ix, elem) => this.$(elem).val(this.model.get((this.$(elem).attr('name')))));
    },
    onRender: function () {
        autocomplete({
            input: this.ui.adminUserName[0],
            className: 'list-group scroll-y',
            emptyMsg: 'No matching Users found',
            minLength: 1,
            showOnFocus: true,
            debounceWaitMs: 200,
            fetch: function (text, update) {
                const users = new Users();
                users.setMatchingText(text);
                users.fetch()
                    .then((results) => {
                        results.forEach((user) => user.label = user.fullName);
                        update(results);
                    });
            },
            render: (item) => {
                return $(templates.siteAddUserRow(item))[0];
            },
            onSelect: (item) => {
                this.ui.adminUserName.val(item.label);
                this.ui.adminEmail.text(item.email);
                this.ui.adminUserId.val(item.id);
            }
        });

        if (this.model.id) {
            this.collections.programs.reset();
            this.programsList = this.showChildView('sitePrograms', new ProgramsList({
                collection: this.collections.programs,
                rowTemplate: this.rowTemplate
            }));

            this.collections.users.reset();
            this.usersList = this.showChildView('siteUsers', new OrganizationUsersView({
                organizationUserModel: new SiteUserModel({ siteId: this.model.id }),
                collection: this.collections.users
            }));
        }

        const siteName = (this.model.id) ? this.model.get('name') : this.title;
        this.ui.siteName.text(siteName);
    },
    onDelete: function () {
        this.model.destroy()
            .always(() => this.trigger('navigate', 'sites'));

        return false;
    },
    onCancel: function () {
        if (this.isModal) {
            this.trigger('modal:hide');
        } else {
            this.trigger('navigate', 'sites');
        }
        return false;
    },
    onSubmit: function () {
        const data = _.mapValues(_.keyBy(this.ui.form.serializeArray(), 'name'), 'value');
        this.model.save(data)
            .then(() => {
                if (this.isModal) {
                    const channel = Radio.channel('site');
                    channel.request('site', this.model.id, this.model.getName());
                    this.trigger('modal:hide');
                } else {
                    if (this.$el.hasClass('is-existing')) {
                        this.refreshUsers();
                    } else {
                        this.$el.removeClass('is-new').addClass('is-existing');
                        this.trigger('navigate', `site/${this.model.id}`);
                    }
                }
            });

        return false;
    },
    onCreateUser: function () {
        const channel = Radio.channel('user');
        channel.reply('user', (userId, fullName, email) => {
            // console.log('new user', userId);

            this.ui.adminUserName.val(fullName);
            this.ui.adminEmail.text(email);
            this.ui.adminUserId.val(userId);

            const siteUserModel = new SiteUserModel({
                siteId: this.model.id,
                userId
            });
            siteUserModel.save()
                .then(() => this.refreshUsers());
        });
        this.trigger('modal:show', new UserView(0, true));
        return false;
    },
    refreshPrograms: function () {
        this.collections.programs.reset();
        this.collections.programs.fetch();
    },
    refreshUsers: function () {
        this.collections.users.reset();
        this.collections.users.fetch();
    }
});
