'use strict';

const
    Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    //
    Table = require('../../table/table'),
    //
    templates = require('../../../utilities/handlebars').templates;

const UploadModel = Backbone.Model.extend({
    urlRoot: '/upload/url'
});

const DownloadModel = Backbone.Model.extend({
    urlRoot: '/download/url',
    idAttribute: 'fileId',
    sync: function (method, model, options) {
        options = options || {};
        options.dataType = 'text';
        return Backbone.sync.apply(this, [method, model, options]);
    }
});

const DocumentModel = Backbone.Model.extend({
    urlRoot: '/document',
    idAttribute: 'fileId'
});

const ProgramDocuments = Backbone.Collection.extend({
    url: '/documents/program',
    //
    offset: 0,
    count: Number.MAX_SAFE_INTEGER,
    sortColumns: 'name',
    sortDirection: 'ASC',
    //
    initialize: function (options) {
        this.programId = options.programId;
        this.organizationId = options.organizationId;
        this.organizationRoleId = options.organizationRoleId;

        this.on('reset', () => {
            this.offset = 0;
        });
    },
    sync: function (method, model, options) {
        options = options || {};
        options.data = {
            programId: this.programId,
            organizationId: this.organizationId,
            organizationRoleId: this.organizationRoleId,
            matchingText: this.matchingText,
            sortColumns: this.sortColumns,
            sortDirection: this.sortDirection,
            offset: this.offset,
            count: this.count
        };
        return Backbone.sync.apply(this, [method, model, options]);
    },
    parse: function (results) {
        this.offset += results && results.length || 0;
        return results;
    },
    getMatchingText: function () {
        return this.matchingText;
    },
    setMatchingText: function (matchingText) {
        this.matchingText = matchingText;
    },
    getSortColumns: function () {
        return this.sortColumns;
    },
    setSortColumns: function (sortColumns) {
        this.sortColumns = sortColumns;
    },
    getSortDirection: function () {
        return this.sortDirection;
    },
    setSortDirection: function (sortDirection) {
        this.sortDirection = sortDirection;
    }
});

module.exports = Marionette.View.extend({
    attributes: {
        class: 'program-materials',
        'data-name': 'program-materials'
    },
    ui: {
        uploadButton: '.js-upload-button',
        uploadFile: '.js-upload-file',
        downloadButton: '.js-download-button',
        deleteButton: '.js-delete-button'
    },
    events: {
        'click @ui.uploadButton': 'onUpload',
        'change @ui.uploadFile': 'onFileSelected',
        'click @ui.downloadButton': 'onDownloadFile',
        'click @ui.deleteButton': 'onDeleteFile'
    },
    tagName: 'div',
    template: templates.programMaterials,
    //
    initialize: function (options) {
        this.programModel = options.programModel;
        this.developerModel = options.developerModel;
        this.programStatuses = options.programStatuses;
        this.programDeveloperStatuses = options.programDeveloperStatuses;
        this.programSiteStatuses = options.programSiteStatuses;
        // console.log('programStatuses', this.programStatuses.length);
        this.model = this.programStatuses.findWhere({ statusName: 'developerInvited' });
        this.programStatuses.on('sync', () => {
            this.model = this.programStatuses.findWhere({ statusName: 'developerInvited' });
            if (this.model) {
                this.render();
            }
        });

        if (this.programModel && this.developerModel) {
            const documents = new ProgramDocuments({
                programId: this.programModel.id,
                organizationId: this.developerModel.id,
                organizationRoleId: 2
            });
            this.listenTo(documents, 'sync', this.render);
            documents.fetch()
                .then(() => {
                    this.documents = documents;
                    this.render();
                });
        }
    },
    onRender: function () {
        if (this.documents) {
            const documentGroups = this.documents.groupBy('category');
            Object.keys(documentGroups).forEach((key) => {
                this.$(`[data-category="${key}"]`)
                    .find('.fa-checkbox input[type="checkbox"]')
                    .prop('checked', !!documentGroups[key].length);
                const regionName = `document-list-${key}`;
                this.addRegion(regionName, `div[data-region=${regionName}]`);
                this.showChildView(regionName, new Table({
                    collection: new Backbone.Collection(documentGroups[key]),
                    rowTemplate: templates.documentListRow
                }));
            });
        }
    },
    onUpload: function (event) {
        const $elem = this.$(event.currentTarget);
        const $uploader = $elem.siblings('.js-upload-file');
        $uploader.click();
    },
    onFileSelected: function (event) {
        const $elem = this.$(event.currentTarget);
        const file = $elem[0].files[0];

        if (file) {
            // Clear out the <input> value, in case we want to select the same file again.
            $elem.val('');
            $elem.closest('div').addClass('spinner');
            $elem.siblings('.js-upload-button').attr('hidden', true);

            const organizationRoleId = this.developerModel.get('organizationRoleId');
            const category = $elem.closest('[data-category]').data('category');
            const uploader = new UploadModel({
                programId: this.programModel.id,
                organizationId: this.developerModel.id,
                organizationRoleId: organizationRoleId,
                fileName: file.name,
                fileType: file.type,
                category: category
            });

            uploader.save()
                .then(results => {
                    const requestOptions = {
                        method: 'PUT',
                        body: file,
                        redirect: 'follow'
                    };
                    return fetch(results.data.url, requestOptions);
                })
                .then(() => {
                    this.programStatuses.fetch();
                    this.programDeveloperStatuses.fetch();
                    this.programSiteStatuses.fetch();
                })
                .always(() => {
                    this.refreshDocuments();
                });
        }
    },
    onDownloadFile: function (event) {
        const $elem = this.$(event.currentTarget);
        const fileId = $elem.closest('[data-file-id]').data('file-id');
        const downloadModel = new DownloadModel({ fileId });
        downloadModel.fetch()
            .then((url) => window.open(url));
        return false;
    },
    onDeleteFile: function (event) {
        const $elem = this.$(event.currentTarget);
        const fileId = $elem.closest('[data-file-id]').data('file-id');
        const documentModel = new DocumentModel({ fileId });
        documentModel.destroy()
            .then(() => {
                this.programStatuses.fetch();
                this.programDeveloperStatuses.fetch();
                this.programSiteStatuses.fetch();
            })
            .always(() => this.refreshDocuments());
        return false;
    },
    refreshDocuments: function () {
        this.documents.reset();
        this.documents.fetch();
    }
});
