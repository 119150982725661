'use strict';

const Marionette = require('backbone.marionette'),
    _ = require('lodash'),
    //
    ProgramQuestions = require('../../../collections/programQuestions'),
    Questions = require('../../../collections/questions'),
    Answer = require('../../../models/answer'),
    Answers = require('../../../collections/answers'),
    SiteAnswers = require('../../../collections/siteAnswers'),
    Responses = require('../../../collections/responses'),
    ProgramDetails = require('../../../models/programDetails'),
    //
    PreviewDetailsView = require('../../preview/previewDetails'),
    ResearchTabView = require('./researchTab'),
    //
    utils = require('../../../utilities/utils'),
    templates = require('../../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'reduce-data',
        'data-name': 'reduce-data'
    },
    ui: {
        allAutomatic: '.js-all-automatic',
        allManual: '.js-all-manual',
        automaticManual: '.js-automatic-manual',
        showAllQuestions: '.js-show-all-questions',
        submit: '.js-submit-button'
    },
    events: {
        'click @ui.allAutomatic': 'onAllAutomatic',
        'click @ui.allManual': 'onAllManual',
        'change @ui.automaticManual': 'onAutomaticManual',
        'change @ui.showAllQuestions': 'onShowAllQuestions',
        'click @ui.submit': 'onSubmit'
    },
    regions: {
        previewDetails:  {el: 'div[data-region=preview-details]',  replaceElement: true},
        researchTab: {el: 'div[data-region=research-tab]', replaceElement: true}
    },
    tagName: 'div',
    template: templates.reduceData,
    //
    initialize: function (options) {
        this.organizationId = options.organizationId;
        this.programId = options.programId;
        this.programName = options.programName;

        this.programQuestions = new ProgramQuestions(options);
        const programQuestionsPromise = this.programQuestions.fetch();

        this.model = new ProgramDetails({ id: this.programName });
        const programDetailsPromise = this.model.fetch();

        const responsesPromise = this.getResponses();

        this.siteAnswers = new SiteAnswers();
        this.siteAnswers.setSurveyType('reduce');
        this.siteAnswers.setProgramId(options.programId);
        const siteAnswersPromise = this.siteAnswers.fetch();

        this.sites = options.sitesList;

        Promise.all([ responsesPromise, programDetailsPromise, siteAnswersPromise, programQuestionsPromise ])
            .then(([ responses ]) => {
                this.responses = responses;
                this.reductionTemplate = templates.reduceDetails;
                this.computeAnswers();
                this.render();
            });
    },
    getResponses: function () {
        const responses = new Responses();

        responses.setCount(Number.MAX_SAFE_INTEGER);

        return responses.fetch();
    },
    computeAnswers: function () {
        const programQuestions = this.model.get('questions');
        const programAnswers = this.model.get('answers');

        this.siteAnswers.each((a) => {
            const site = this.sites.find({ id: a.get('organizationId') });
            a.set('siteName', site.get('name'));
            a.set('shortName', site.get('shortName'));
            a.set('answer', a.get('responseValue') || a.get('response'));
        });

        programQuestions.forEach((q) => {
            q.responses = _.filter(this.responses, { questionId: q.id });

            if (!q.responses.length) {
                console.log('No responses for question', q.id, q.question);
            }

            q.responses.forEach((r) => {
                r.answers = _.filter(programAnswers, { questionId: q.id, responseId: r.id });
                q.siteAnswers = this.siteAnswers.where({ reducedResponseCode: r.responseCode });
                if (!r.answers.length) {
                    // No answer given. Make one up from the Site answers.
                    // console.log('No answer for question', q.id, q.question, q.siteAnswers.length, 'Site answers');
                }
            });
        });
    },
    onRender: function () {
        if (this.reductionTemplate) {
            this.showChildView('previewDetails', new PreviewDetailsView({ model: this.model, template: this.reductionTemplate, showAllQuestions: this.showAllQuestions }));
            this.$('.js-show-all-questions').prop('checked', !!this.showAllQuestions);

            const questions = this.model.get('questions');
            questions.forEach((q) => {
                // if (q.id !== 1170) {
                //     return;
                // }
                const $qElem = this.$(`#nav-reduce-surveyOfDeliverySites .js-question-${q.id}`);
                $qElem.find('.cs-right-column,.cs-levels-answers').each((i, o) => {
                    const $o = this.$(o);
                    if (q.cbQuestionType === 'multiselectTable' || q.cbQuestionType === 'multiselectTableWithOptionalText') {
                        utils.multiselectTableMetaData(q);
                    }
                    const answerTemplate = templates.questionAnswers(q);
                    $o.html(answerTemplate);
                    const programQuestionModel = this.programQuestions.get(q.id) && this.programQuestions.get(q.id).toJSON() || { questionId: q.id };
                    this.disableManualInput($o, programQuestionModel.reductionIsManual);
                    $o.prepend(templates.programQuestionState(programQuestionModel));
                });
                if (q.siteAnswers.length) {
                    const template = templates.siteAnswers(q.siteAnswers.map((a) => a.toJSON()));
                    $qElem.after(template);
                }
            });

            this.showChildView('researchTab', new ResearchTabView({
                programDetails: this.model,
                programId: this.programId,
                organizationId: this.organizationId
            }));
        }
    },
    onAutomaticManual: function (event) {
        const $el = this.$(event.currentTarget);
        const value = $el.val();
        const questionId = $el.data('question-id');
        const programQuestion = this.programQuestions.get({ questionId }) || this.programQuestions.add({ questionId });
        programQuestion.set({ reductionIsManual: value });
        this.disableManualInput($el.siblings(), value === '1');
    },
    disableManualInput: function ($el, reductionIsManual) {
        $el.find('input,select').prop('disabled', !reductionIsManual);
    },
    onAllAutomatic: function () {
        this.$('.js-automatic-manual').each((i, el) => {
            if (el.selectedIndex === 1) {
                this.$(el).prop('value', 0).change();
            }
        });
    },
    onAllManual: function () {
        this.$('.js-automatic-manual').each((i, el) => {
            if (el.selectedIndex === 0) {
                this.$(el).prop('value', 1).change();
            }
        });
    },
    onShowAllQuestions: function (event) {
        this.showAllQuestions = this.$(event.currentTarget).is(':checked');
        this.render();
    },
    onSubmit: function (event) {
        const $elem = this.$(event.currentTarget);

        const $body = this.$el.closest('body');
        $body.css({ cursor: 'wait' });
        $elem.css({ cursor: 'wait' });

        const manuals = this.programQuestions.filter((m) => {
            const manual = m.get('reductionIsManual');
            return manual && manual !== '0';
        });
        const questions = manuals.map((m) => m.get('questionId'));
        const $responses = this.$('[data-response-id]:not(:disabled)').filter((ix, r) => questions.includes(this.$(r).data('question-id')));
        const answers = this.constructAnswers(new Questions(this.model.get('questions')), $responses, new Answers(this.model.get('answers')));
        answers.setSurveyType('delivery');
        answers.setOrganizationId(this.organizationId);
        answers.setProgramId(this.programId);
        Promise.all([ answers.save(), this.programQuestions.save() ])
            .then(() => this.trigger('modal:hide'))
            .finally(() => $body.css({ cursor: 'default'}));
        return false;
    },
    constructAnswers: function (questions, $responses, originalAnswers) {
        const answers = new Answers();

        //
        // This is almost exactly the same code as `constructAnswers()` in researchTab.js. Please keep in sync, or refactor.
        //
        $responses.each((ix, elem) => {
            const $elem = this.$(elem);
            const questionId = $elem.data('question-id');
            const responseId = $elem.data('response-id');
            const nodeName = $elem.prop('nodeName');
            const nodeType = $elem.attr('type');
            const checked = $elem.is(':checked');
            const checkable = (nodeName === 'OPTION' || nodeType === 'checkbox' || nodeType === 'radio');
            const value = (checkable) ? checked : $elem.val();

            const thisQuestion = questions.get(questionId);

            if (!responseId) {
                return;
            }

            const responseResponseValue = thisQuestion.get('responses').filter((r) => r.id === responseId )[0].responseValue;

            const answer = originalAnswers.get({ questionId, responseId });

            if (nodeType === 'checkbox' && $elem.data('response-id-0')) {
                // This is a true checkbox representing two possible responseId's, not a Multiple Select rendered as a checkbox.
                // Some types of questions haveIde the 'Yes' Response at index 0, and others have the 'Yes' Response at index 1.
                const response_index_0 = $elem.data('response-id-0');
                const response_index_1 = $elem.data('response-id-1');
                const response_id_no_index = $elem.data('response-index-no');
                const response_unchecked = (response_id_no_index) ? response_index_1 : response_index_0;
                const response_checked = (response_id_no_index) ? response_index_0 : response_index_1;

                const answer_unchecked = this.answers.get({questionId, responseId: response_unchecked});
                const answer_checked = this.answers.get({questionId, responseId: response_checked});

                if (checked) {
                    if (!answer_checked) {
                        answers.add(new Answer({
                            questionId,
                            responseId : response_checked,
                            answer: checked
                        }));
                    }
                    if (answer_unchecked) {
                        // Shouldn't really be necessary, since we're not creating an Answer for an unchecked checkbox.
                        // But some earlier code was doing just that, so we need to retire it, if it is found.
                        answers.add(new Answer({
                            questionId,
                            responseId: response_unchecked,
                            programDataSetId: answer_unchecked.get('programDataSetId'),
                            answerId: answer_unchecked.get('answerId'),
                            answer: answer_unchecked.get('responseValue'),
                            retired: true
                        }));
                    }
                } else {
                    if (answer_checked) {
                        answers.add(new Answer({
                            questionId,
                            responseId: response_checked,
                            programDataSetId: answer_checked.get('programDataSetId'),
                            answerId: answer_checked.get('answerId'),
                            answer: answer_checked.get('responseValue'),
                            retired: true
                        }));
                    }
                }

                // console.log(questionId, responseId, response_unchecked, response_checked, checked, !!answer_unchecked, !!answer_checked);
            } else if (nodeName === 'OPTION' && !!$elem.data('single-response')) {
                if (checked) {
                    const selectedValue = $elem.val();

                    if (answer) {
                        const answerId = answer.get('answerId');
                        const responseValue = answer.get('responseValue');
                        const programDataSetId = answer.get('programDataSetId');

                        if (responseValue !== selectedValue) {
                            answers.add(new Answer({
                                questionId,
                                responseId,
                                programDataSetId,
                                answerId,
                                answer: responseResponseValue || selectedValue,
                                retired: false
                            }));
                        }
                    } else {
                        answers.add(new Answer({
                            questionId,
                            responseId,
                            answer: responseResponseValue || selectedValue
                        }));
                    }
                }
            } else if (answer) {
                // If there was a previous answer, see if it has changed.
                const answerId = answer.get('answerId');
                const responseValue = answer.get('responseValue');

                // If the element is "checkable", the existence of an answer indicates it is in the "true" state, regardless of the "responseValue".
                const hasValue = !!value;
                const hasAnswer = !!answer;
                if ((checkable && hasValue !== hasAnswer) || (!checkable && value !== responseValue)) {
                    answers.add(new Answer({
                        questionId,
                        responseId,
                        programDataSetId: answer.get('programDataSetId'),
                        answerId: answerId,
                        answer: responseResponseValue || value,
                        retired: !value
                    }));
                    // console.log(nodeName, nodeType, checkable, value, responseValue);
                }
            } else {
                // If there was no previous answer, see if the new answer is "positive".
                if (value) {
                    answers.add(new Answer({
                        questionId,
                        responseId,
                        answer: responseResponseValue || value
                    }));
                }
            }
        });

        return answers;
    }
});
