'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    // _ = require('lodash'),
    //
    Recover = require('../recover/recover'),
    //
    UserLogin = require('../../singletons/userLogin'),
    //
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'login',
        'data-name': 'login'
    },
    ui: {
        form: 'form',
        username: '.js-username',
        password: '.js-password',
        rememberMe: '.js-remember-me',
        passwordToggle: '.js-password-toggle',
        forgotPassword: '.js-forgot-password'
    },
    events: {
        'click @ui.passwordToggle': 'togglePassword',
        'click @ui.forgotPassword': 'forgotPassword',
        'submit': 'onSubmit'
    },
    tagName: 'div',
    template: templates.login,
    //
    initialize: function () {
        this.listenTo(UserLogin, 'change', this.updateUserName);
    },
    templateContext: function () {
        return {
            userName: UserLogin.get('username')
        };
    },
    updateUserName: function () {
        const username = UserLogin.get('username');
        this.ui.username.val(username);
    },
    togglePassword: function (event) {
        let type = this.ui.password.attr('type');
        let iconName;

        if (type === 'password') {
            type = 'text';
            iconName = 'fa-eye-slash';
        } else {
            type = 'password';
            iconName = 'fa-eye';
        }

        this.ui.password.attr('type', type);
        this.$(event.currentTarget).find('.fa-eye,.fa-eye-slash').removeClass('fa-eye fa-eye-slash').addClass(iconName);

        return false;
    },
    forgotPassword: function () {
        UserLogin.set('username', this.ui.username.val());
        this.trigger('modal:show', new Recover());

        return false;
    },
    onSubmit: function () {
        const username = this.ui.username.val();
        const password = this.ui.password.val();
        const rememberMe = this.ui.rememberMe.prop('checked');

        UserLogin.login(username, password, rememberMe);

        return false;
    }
});
