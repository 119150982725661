'use strict';

const Programs = require('./programs');

module.exports = Programs.extend({
    //
    url: function () {
        return '/programs/developer/' + this.developerId;
    },
    //
    developerId: 0,
    //
    initialize: function (options) {
        this.developerId = options.developerId;
        this.on('reset', () => {
            this.offset = 0;
        });
    },
    getDeveloperId: function () {
        return this.developerId;
    },
    setDeveloperId: function (developerId) {
        this.developerId = developerId;
    }
});
