'use strict';

const Marionette = require('backbone.marionette'),
    Radio = require('backbone.radio'),
    autocomplete = require('autocompleter'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    Developer = require('../../models/developer'),
    Developers = require('../../collections/developers'),
    ProgramStatus = require('../../collections/programStatus'),
    ProgramDeveloperStatus = require('../../collections/programDeveloperStatus'),
    ProgramSiteStatus = require('../../collections/programSiteStatus'),
    ProgramDeliverySites = require('../../collections/programDeliverySites'),
    ProgramConsumerSites = require('../../collections/programConsumerSites'),
    SiteStatus = require('../../collections/siteStatus'),
    DeliverySites = require('../../collections/deliverySites'),
    DeliverySite = require('../../models/deliverySite'),
    ConsumerSites = require('../../collections/consumerSites'),
    ConsumerSite = require('../../models/consumerSite'),
    Program = require('../../models/program'),
    Publish = require('../../models/publish'),
    //
    CommunicationsView = require('./communications/communications'),
    DeveloperView = require('../developer/developer'),
    ProgramMaterialsView = require('./programMaterials/programMaterials'),
    ProgramStatusView = require ('./programStatus/programStatus'),
    ReduceDataView = require('./reduceData/reduceData'),
    TimelineView = require('../timeline/timeline'),
    DeliverySiteView = require('../site/deliverySite'),
    ConsumerSiteView = require('../site/consumerSite'),
    SitesListView = require('../sites/sitesList/sitesList'),
    //
    Enumerations = require('../../singletons/enumerations'),
    User = require('../../singletons/user'),
    //
    templates = require('../../utilities/handlebars').templates;

const ProgramDeliverySiteModel = DeliverySite.extend({
    idAttribute: 'programId',
    urlRoot: function () {
        return `/site/program/${this.get('siteId')}`;
    }
});

const ProgramConsumerSiteModel = ConsumerSite.extend({
    idAttribute: 'programId',
    urlRoot: function () {
        return `/consumer/program/${this.get('siteId')}`;
    }
});

module.exports = Marionette.View.extend({
    attributes: {
        class: 'program',
        'data-name': 'program'
    },
    ui: {
        programName: '.js-program-name',
        form: 'form',
        saveEdit: '.js-save-edit-button',
        delete: '.js-delete-button',
        cancel: '.js-cancel-button',
        preview: '.js-preview-button',
        publish: '.js-publish-button',
        submit: '.js-submit-button',
        reduce: '.js-reduce-data-button',
        updateStatus: '.js-update-status',
        programStatusName: '.js-program-status',
        programStatusId: '[name="programStatusId"]',
        developerId: '[name="developerOrganizationId"]',
        developerName: '[name="developerOrganizationName"]',
        createDeveloper: '.js-create-developer',
        sitesTab: '#sites-tab',
        selectedSiteName: '.js-selected-site-name',
        createSite: '.js-create-site',
        sitesCount: '.js-sites-count',
        addSites: '.js-add-sites',
        sitesDone: '.js-sites-done',
        siteName: '[name="programSiteName"]'
    },
    events: {
        'click @ui.updateStatus': 'onUpdateStatus',
        'click @ui.saveEdit': 'onSubmit',
        'click @ui.delete': 'onDelete',
        'click @ui.cancel': 'onCancel',
        'click @ui.publish': 'onPublish',
        'click @ui.submit': 'onSubmit',
        'click @ui.reduce': 'onReduce',
        'click @ui.createDeveloper': 'onCreateDeveloper',
        'click @ui.createSite': 'onCreateSite',
        'click @ui.addSites': 'setAdding',
        'click @ui.sitesDone': 'setAddingDone',
        'click [data-action="add-site"]': 'onAddSite',
        'show.bs.tab @ui.sitesTab': 'onSitesTabChange',
        'click [data-action="select-site"]': 'onSelectSite',
        'change [data-action="multi-site"]': 'onMultiSite',
        'click [data-action="remove-site"]': 'onRemoveSite'
    },
    childViewEvents: {
        'refresh.sites': 'refreshSites'
    },
    childViewTriggers: {
        'modal:show': 'modal:show',
        'modal:hide': 'modal:hide'
    },
    regions: {
        developerOverview: {el: 'div[data-region=developer-overview]', replaceElement: true},
        developerCommunications: {el: 'div[data-region=developer-communications]', replaceElement: true},
        developerTimeline: {el: 'div[data-region=developer-timeline]', replaceElement: true},
        programMaterials: {el: 'div[data-region=program-materials]', replaceElement: true},
        programSiteTimeline: {el: 'div[data-region=program-site-timeline]', replaceElement: true},
        siteTimeline: {el: 'div[data-region=site-timeline]', replaceElement: true},
        siteCommunications: {el: 'div[data-region=site-communications]', replaceElement: true},
        consumerSiteList: {el: 'div[data-region=consumer-site-list]', replaceElement: true},
        programSiteList: {el: 'div[data-region=program-site-list]', replaceElement: true}
    },
    tagName: 'div',
    getTemplate: function () {
        return templates.program[User.getRole()];
    },
    //
    initialize: function (programId, tabName, developerId) {
        programId = parseInt(programId, 10);
        // We can enter here from two possible routes: '/program' and '/program/:programId'
        // '/program' is the easy case. We are creating a new program and all the form fields can be blank.
        // '/program/:programId' means we are editing an existing program. We fetch the data for this program and fill in the form.
        if (programId) {
            this.$el.addClass('is-existing');
            this.model = new Program({ id: programId });
            this.listenTo(this.model, 'sync', () => {
                this.developerId = this.model.get('developerOrganizationId');
                this.render();
            });
            this.model.fetch();

            this.siteFactories = {
                'sites-delivery-tab': {
                    View: DeliverySiteView,
                    Site: DeliverySite,
                    Sites: DeliverySites,
                    ProgramSiteModel: ProgramDeliverySiteModel
                },
                'sites-consumer-tab': {
                    View: ConsumerSiteView,
                    Site: ConsumerSite,
                    Sites: ConsumerSites,
                    ProgramSiteModel: ProgramConsumerSiteModel
                }
            };

            this.collections = {
                programDeveloperStatus: new ProgramDeveloperStatus({ programId: this.model.id }),
                programSiteStatus: new ProgramSiteStatus({ programId: this.model.id }),
                programStatus: new ProgramStatus({ programId: this.model.id }),
                consumerSites: new ProgramConsumerSites({ programId }),
                deliverySites: new ProgramDeliverySites({ programId: programId })
            };

            this.collections.consumerSites.setCount(Number.MAX_SAFE_INTEGER);
            this.collections.deliverySites.setCount(Number.MAX_SAFE_INTEGER);

            this.siteCommunicationsTemplate = templates.siteCommunications;
            this.collections.sites = this.collections.deliverySites;
            this.sitesTabId = 'sites-delivery-tab';

            this.collections.programStatus.fetch();
            this.listenTo(this.collections.programStatus, 'saved', this.statusUpdated);

            this.listenTo(this.collections.deliverySites, 'sync', () => {
                this.ui.sitesCount.text(this.collections.deliverySites.length);
            });
        } else {
            this.$el.addClass('is-new');
            this.model = new Program();
            this.developerId = developerId;
        }

        this.currentTabId = this.tabId(tabName);
    },
    tabId: function (tabName) {
        return (tabName) ? `#nav-${tabName}-tab` : (this.model.id) ? '#nav-developer-tab' : '#nav-overview-tab';
    },
    onUpdateStatus: function () {
        this.trigger('modal:show', new ProgramStatusView({ collection: this.collections.programStatus }));
        return false;
    },
    statusUpdated: function () {
        const programStatus = this.collections.programStatus;
        let lastCompleted;
        for (let j=0; j < programStatus.length; j++) {
            const model = programStatus.at(j);
            // Don't even consider statuses that are not to be displayed.
            if (!model.get('statusDisplayName')) {
                continue;
            }
            const status = model.get('status');
            // Make sure we don't count 'completed future'
            if (status === 'completed') {
                lastCompleted = model;
            } else {
                break;
            }
        }

        const programStatusId = lastCompleted ? lastCompleted.id : 0;
        const displayName = lastCompleted ? lastCompleted.get('statusDisplayName') : '';

        this.ui.programStatusId.val(programStatusId);
        this.ui.programStatusName.text(displayName);
        this.refreshCollections();
    },
    setFieldsFromModel: function () {
        this.ui.form.find(':input').each((ix, elem) => this.$(elem).val(this.model.get((this.$(elem).attr('name')))));
    },
    onRender: function () {
        const that = this;

        this.setFieldsFromModel();

        autocomplete({
            input: this.ui.developerName[0],
            className: 'list-group scroll-y',
            emptyMsg: 'No matching Developers found',
            minLength: 1,
            debounceWaitMs: 200,
            showOnFocus: true,
            fetch: function (text, update) {
                const developers = new Developers();
                developers.setMatchingText(text);
                developers.fetch()
                    .then((results) => {
                        const names = _.map(results, (value) => {
                            return {
                                id: value.id,
                                label: value.name
                            };
                        });
                        update(names);
                    });
            },
            render: (item) => $('<div>').addClass('list-group-item').text(item.label)[0],
            onSelect: (item) => {
                this.developerId = item.id;
                this.ui.developerId.val(item.id);
                this.ui.developerName.val(item.label);
            }
        });

        autocomplete({
            input: this.ui.siteName[0],
            className: 'list-group scroll-y',
            emptyMsg: 'No matching Sites found',
            minLength: 1,
            showOnFocus: true,
            debounceWaitMs: 200,
            fetch: function (text, update) {
                const SitesFactory = that.siteFactories[that.sitesTabId].Sites;
                const sites = new SitesFactory();
                sites.setMatchingText(text);
                sites.fetch()
                    .then((results) => {
                        const names = _.map(results, (value) => {
                            return {
                                id: value.id,
                                label: value.name
                            };
                        });
                        update(names);
                    });
            },
            render: (item) => {
                return $(templates.programAddSiteRow(item))[0];
            },
            onSelect: (item) => {
                const ProgramSiteModelFactory = that.siteFactories[that.sitesTabId].ProgramSiteModel;
                const programSiteModel = new ProgramSiteModelFactory({
                    programId: this.model.id,
                    siteId: item.id
                });
                programSiteModel.save()
                    .then(() => this.refreshCollections());
            }
        });

        if (this.developerId && !this.developerModel) {
            // We have a developerId, but we haven't fetched the model yet.
            this.developerModel = new Developer({ id: this.developerId });
            this.developerModel.fetch()
                .then(model => {
                    this.ui.developerId.val(model.id);
                    this.ui.developerName.val(model.name);
                });
        }

        if (this.model.id) {
            this.developerCommunications = this.showChildView('developerCommunications', new CommunicationsView({
                organizationType: 'developer',
                organizationId: this.developerId,
                template: templates.developerCommunications[User.getRole()],
                collection: this.collections.programStatus,
                programModel: this.model
            }));
            this.developerTimeline = this.showChildView('developerTimeline', new TimelineView({
                collection: this.collections.programDeveloperStatus
            }));
            this.programMaterials = this.showChildView('programMaterials', new ProgramMaterialsView({
                developerModel: this.developerModel,
                programModel: this.model,
                programStatuses: this.collections.programStatus,
                programDeveloperStatuses: this.collections.programDeveloperStatus,
                programSiteStatuses: this.collections.programSiteStatus
            }));
            this.programSiteTimeline = this.showChildView('programSiteTimeline', new TimelineView({
                collection: this.collections.programSiteStatus
            }));
            this.sitesList = this.showChildView('programSiteList', new SitesListView({
                collection: this.collections.deliverySites,
                rowTemplate: templates.programSitesListRow
            }));
            this.consumerSiteList = this.showChildView('consumerSiteList', new SitesListView({
                collection: this.collections.consumerSites,
                rowTemplate: templates.programConsumerSitesListRow
            }));
        }

        const programName = (this.model.id) ? this.model.get('name') : 'Program';
        this.ui.programName.text(programName);

        this.$('#nav-tab [data-toggle="tab"]').on('shown.bs.tab', (event) => {
            this.currentTabId = '#' + this.$(event.currentTarget).attr('id');
        });

        if (this.currentTabId && !this.$(this.currentTabId).length) {
            // Given tab doesn't exist, so just get the default.
            this.currentTabId = this.tabId('');
        }

        if (this.currentTabId) {
            this.showTab(this.currentTabId);
        }

        if (this.sitesTabId) {
            this.showTab('#'+this.sitesTabId);
        }

        this.ui.form.find('.form-control').prop('disabled', User.getRole() !== 'administrator');
    },
    showTab: function (tabId) {
        this.$(tabId).tab('show');
        // This is a bit of a hack, so that we can start off with no tab selected,
        // but still be able to select the first tab. Selecting a different tab works correctly.
        // This fix is just for selecting the first tab.
        const $tab = this.$(this.$(tabId).attr('href'));
        if (!$tab.hasClass('active')) {
            $tab.tab('show');
        }
    },
    onDelete: function () {
        this.model.destroy()
            .always(() => this.trigger('navigate', 'programs'));

        return false;
    },
    onPublish: function (event) {
        const $elem  = this.$(event.currentTarget);
        $elem.prop('disabled', true);
        const publishModel = new Publish({ id: this.model.id });
        publishModel.save()
            .then(() => this.refreshCollections());
    },
    onCancel: function () {
        this.trigger('navigate', 'programs');
        return false;
    },
    onSubmit: function () {
        const data = _.mapValues(_.keyBy(this.ui.form.serializeArray(), 'name'), 'value');
        data.programStatusId = this.ui.programStatusId.val();
        if (data.programStatusId <= 0) {
            data.programStatusId = Enumerations.get('programStatusByName').programCreated.id;
        }
        this.model.save(data)
            .then(() => {
                this.$el.removeClass('is-new').addClass('is-existing');
                this.trigger('navigate', `program/${this.model.id}`);
            });

        return false;
    },
    onReduce: function () {
        this.trigger('modal:show', new ReduceDataView({
            organizationId: this.developerId,
            programId: this.model.id,
            programName: this.model.get('shortName'),
            sitesList: this.collections.deliverySites
        }));
        return false;
    },
    onCreateDeveloper: function () {
        const channel = Radio.channel('developer');
        channel.reply('developer', (developerId, developerName) => {
            this.ui.developerId.val(developerId);
            this.ui.developerName.val(developerName);
        });
        this.trigger('modal:show', new DeveloperView(0, true));
        return false;
    },
    setAdding: function (event) {
        this.$(event.currentTarget).parents('.not-editing').first().removeClass('not-editing').addClass('is-editing');
        return false;
    },
    setAddingDone: function (event) {
        this.$(event.currentTarget).parents('.is-editing').first().removeClass('is-editing').addClass('not-editing');
        return false;
    },
    onCreateSite: function () {
        const ProgramSiteModel = this.siteFactories[this.sitesTabId].ProgramSiteModel;
        const channel = Radio.channel('site');
        channel.reply('site', (siteId) => {
            const programSiteModel = new ProgramSiteModel({
                programId: this.model.id,
                siteId
            });
            programSiteModel.save()
                .then(() =>this.refreshCollections());
        });
        const ViewFactory = this.siteFactories[this.sitesTabId].View;
        this.trigger('modal:show', new ViewFactory(0, true));
        return false;
    },
    onAddSite: function (event) {
        const ProgramModelSiteFactory = this.siteFactories[this.sitesTabId].ProgramSiteModel;
        const siteId = this.$(event.currentTarget).data('add-site');
        const programSiteModel = new ProgramModelSiteFactory({
            programId: this.model.id,
            siteId
        });
        programSiteModel.save()
            .then(() =>this.refreshCollections());

        return false;
    },
    onSitesTabChange: function (event) {
        this.ui.selectedSiteName.text('');
        this.detachChildView('siteTimeline');
        this.detachChildView('siteCommunications');

        let selectedSiteId;

        this.sitesTabId = this.$(event.target).prop('id');
        switch (this.sitesTabId) {
        case 'sites-delivery-tab':
            this.collections.sites = this.collections.deliverySites;
            this.siteCommunicationsTemplate = templates.siteCommunications;
            this.selectedConsumerSiteId = this.$('#sites-consumer .table-active').data('select-site');
            selectedSiteId = this.selectedDeliverySiteId;
            break;
        case 'sites-consumer-tab':
            this.collections.sites = this.collections.consumerSites;
            this.siteCommunicationsTemplate = templates.consumerCommunications;
            this.selectedDeliverySiteId = this.$('#sites-delivery .table-active').data('select-site');
            selectedSiteId = this.selectedConsumerSiteId;
            break;
        }
        if (selectedSiteId) {
            this.selectSite(selectedSiteId);
        }
    },
    onSelectSite: function (event) {
        if (this.$(event.target).is('[data-navigate]')) {
            return;
        }
        const $target = this.$(event.currentTarget);
        $target.addClass('table-active').siblings().removeClass('table-active');
        const siteId = $target.data('select-site');
        this.selectSite(siteId);
    },
    selectSite: function (siteId) {
        const siteModel = this.collections.sites.filter({id: siteId})[0];
        this.ui.selectedSiteName.text(siteModel && siteModel.get('name'));

        this.collections.siteTimelineStatus = new SiteStatus(null, { programId: this.model.id, siteId });
        this.siteTimeline = this.showChildView('siteTimeline', new TimelineView({
            collection: this.collections.siteTimelineStatus
        }));

        this.collections.siteStatus = new SiteStatus(null, { programId: this.model.id, siteId });
        this.collections.siteStatus.setIncludeOptional(true);
        this.collections.siteStatus.fetch()
            .then(() => {
                this.siteCommunications = this.showChildView('siteCommunications', new CommunicationsView({
                    organizationType: 'site',
                    organizationId: siteId,
                    template: this.siteCommunicationsTemplate,
                    collection: this.collections.siteStatus,
                    programModel: this.model
                }));
            });
        this.listenTo(this.collections.siteStatus, 'sync', () => this.collections.siteTimelineStatus.fetch());
    },
    onMultiSite: function (event) {
        const $target = this.$(event.currentTarget);
        const selected = $target.is(':checked');
        const siteId = $target.data('multi-site');
        const ProgramSiteModelFactory = this.siteFactories[this.sitesTabId].ProgramSiteModel;
        const programSiteModel = new ProgramSiteModelFactory({
            programId: this.model.id,
            siteId
        });
        programSiteModel.set('multiSite', selected);
        programSiteModel.save()
            .then(() => this.collections.siteStatus.fetch());

        return false;
    },
    onRemoveSite: function (event) {
        const siteId = this.$(event.currentTarget).data('remove-site');
        const ProgramSiteModelFactory = this.siteFactories[this.sitesTabId].ProgramSiteModel;
        const programSiteModel = new ProgramSiteModelFactory({
            programId: this.model.id,
            siteId
        });
        programSiteModel.destroy()
            .then(() =>this.refreshCollections());

        return false;
    },
    refreshCollections: function () {
        this.collections.deliverySites.reset();
        this.collections.consumerSites.reset();
        this.collections.programDeveloperStatus.fetch();
        this.collections.programSiteStatus.fetch();
        this.collections.programStatus.fetch();
        this.model.fetch();
    }
});
