'use strict';

const Backbone = require('backbone');

module.exports = Backbone.Model.extend({
    urlRoot: '/developer',
    getName: function () {
        return this.get('name');
    }
});
