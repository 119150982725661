'use strict';

const Backbone = require('backbone');

module.exports = Backbone.Model.extend({
    urlRoot: null,      // Abstract model
    getName: function () {
        return this.get('name');
    }
});
