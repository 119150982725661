'use strict';

const Backbone = require('backbone'),
    _ = require('lodash'),
    //
    Router = Backbone.Router.extend({
        /**
         * Define the routes and navigate to the initial route.
         * @param routes - An object who's keys are the routes names.
         * If the value is an object with an `args` field, it is concatenated to form the route.
         * If the `args` field is a string, it is simply concatenated.
         * If it is an array, each element is concatenated to the route name.
         * e.g. {
         *     programs:        true,                           => /programs
         *     reset:           { args: '/:guid' },             => /reset/:guid
         *     user:            { args: [ '', '/:userId' ] },   => /user
         *                                                      => /user/:userId
         *     login:           {}                              => /login
         * }
         * @param defaultRoute - A route to navigate to, if we're at the root.
         * This will be ignored if we're already loaded at a route, e.g. http:/domain.com/login
         * @param ignoreRoute - Don't use the initial route if it is this `ignoreRoute`
         */
        start: function (routes, defaultRoute, ignoreRoute) {
            const initialRoute = window.location.pathname.substr(1);
            _.each(routes, function (value, name) {
                // Each route uses the given name. Those interested can monitor the 'route' event from this router.
                if (_.has(value, 'args')) {
                    const args = value.args;
                    if (_.isArray(args)) {
                        args.forEach((arg) => this.route(name + arg, name));
                    } else {
                        this.route(name + args, name);
                    }
                } else {
                    this.route(name, name);
                }
            }.bind(this));

            const startingRoute = (ignoreRoute === initialRoute) ? defaultRoute : (initialRoute || defaultRoute);
            this.navigate(startingRoute, {trigger: true, replace: true});

            // If the navigate() call doesn't change the route, no events are triggered, so do that ourselves.
            if (startingRoute === initialRoute) {
                const args = startingRoute.split('/');
                const name = args.shift();
                this.trigger('route', name, args);
            }
        }
    });

module.exports = new Router();
