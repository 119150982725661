'use strict';

const UserSites = require('./userSites'),
    DeliverySite = require('../models/deliverySite');

module.exports = UserSites.extend({
    //
    model: DeliverySite,
    //
    url: function () {
        return '/sites/user/' + this.userId;
    }
});
