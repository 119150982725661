'use strict';

const Backbone = require('backbone'),
    _ = require('lodash');

const Enumerations = Backbone.Model.extend({
    //
    url: '/enumeration',
    //
    sync: function (method, model, options) {
        return Backbone.sync(method, model, options)
            .then((response) => {
                _.each(response, (e, key) => {
                    this.set(key + 'Lookup', _.keyBy(e, 'id'));
                });
            });
    }
});

module.exports = new Enumerations();
