'use strict';

const ProgramSites = require('./programSites'),
    DeliverySite = require('../models/deliverySite');

module.exports = ProgramSites.extend({
    //
    model: DeliverySite,
    //
    url: function () {
        return '/sites/program/' + this.programId;
    }
});
