'use strict';

const _ = require('lodash');

module.exports.multiselectTableMetaData = function (question) {
    const prefixMap = {};
    const prefixes = [];
    const suffixes = [];
    const suffixMap = {};

    question.responses.forEach((r) => {
        if (r.overrideQuestionTypeId) {
            return;
        }
        const response = r.response;
        // Split by hyphen or dash. Try with surrounding spaces first, then without.
        let responseParts = response.split(' - ');
        if (responseParts.length !== 2) {
            responseParts = response.split(' – ');
        }
        if (responseParts.length !== 2) {
            responseParts = response.split('-');
        }
        if (responseParts.length !== 2) {
            responseParts = response.split('–');
        }
        if (responseParts.length === 2) {
            const pref = responseParts[0].trim();
            const suff = responseParts[1].trim();

            prefixes.push(pref);
            suffixes.push(suff);

            prefixMap[pref] = prefixMap[pref] || [];
            prefixMap[pref].push(r);
            suffixMap[suff] = true;
        } else {
            console.log(question.id, question.questionTypeId, 'poorly formatted table response', r.response);
        }
    });

    const rows = [];
    const columnTitles = [];

    prefixes.forEach((p) => {
        if (prefixMap[p]) {
            rows.push({
                rowTitle: p,
                responses: prefixMap[p]
            });
            prefixMap[p] = false;
        }
    });

    suffixes.forEach((s) => {
        if (suffixMap[s]) {
            columnTitles.push(s);
            suffixMap[s] = false;
        }
    });

    question.rows = rows;
    question.columnTitles = columnTitles;
};

module.exports.getResearchCategoryQuestions = function (questions, answers) {
    const studiesProperties = {
            level1Value:'Research Evidence', level2Value:'Study Characteristics'
        },
        pwdProperties = {
            level3Value:'Eligibility Criteria for Study Inclusion of Persons with Dementia'
        },
        cgProperties = {
            level3Value:'Eligiblity Criteria for Study Inclusion of Caregivers'
        },
        sourceProperties = {
            level3Value:'Source of Data'
        },
        fundersProperties = {
            level3Value:'Study Description', profileLabel:'Study Funders'
        },
        datesProperties = {
            level3Value:'Study Description', profileLabel:'Study start and end years'
        },
        q_questions = _.filter(questions, studiesProperties),
        q_questionsLookup = _.keyBy(q_questions, 'id'),
        q_answers = _.filter(answers, function (answer) {
            return !!q_questionsLookup[answer.questionId];
        }),
        q_qpwd = _.filter(q_questions, pwdProperties),
        q_qcg = _.filter(q_questions, cgProperties),
        q_qsource = _.filter(q_questions, sourceProperties),
        q_qfunders = _.filter(q_questions, fundersProperties),
        q_qdates = _.filter(q_questions, datesProperties),
        q_nonStudyQuestionsLookup = _.keyBy([].concat(q_qpwd,q_qcg,q_qsource,q_qfunders,q_qdates), 'id'),
        q_qstudy = _.reject(q_questions, function (q) {
            return !!q_nonStudyQuestionsLookup[q.id];
        });

    return {
        q_questions,
        q_questionsLookup,
        q_answers,
        q_qpwd,
        q_qcg,
        q_qsource,
        q_qfunders,
        q_qdates,
        q_nonStudyQuestionsLookup,
        q_qstudy
    };
};
