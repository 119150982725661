'use strict';

const handlebars = require('handlebars'),
    //
    enumerations = require('../singletons/enumerations'),
    //
    templates = require('../../builds/adminTemplates'),
    moment = require('moment-timezone'),
    _ = require('lodash'),
    helpers = {};

handlebars.templates = templates;

module.exports = {
    initialize: function (callback) {

        handlebars.registerHelper('rowTemplate', function (template, values) {
            return template(values !== 'header' ? values : {header: true});
        });
        handlebars.registerHelper('join', function (completed, delimiter) {
            const d = _.isString(delimiter) ? delimiter : ',';
            return completed.join(d);
        });
        handlebars.registerHelper('lessons', function (available, completed) {
            const o = {};
            _.each(available, function (lesson) {
                o[lesson] = lesson + '*'; // start with all marked incomplete
            });
            _.each(completed, function (lesson) {
                o[lesson] = lesson + (o[lesson] ? '' : '!'); // '!' indicates completed lesson that was not in available list...
            });
            return _.values(o).join(',');
        });


        handlebars.registerHelper('camelCase', function (s) {
            return _.camelCase(s);
        });
        handlebars.registerHelper('concat', function () {
            return _.slice(arguments, 0, arguments.length - 1).join(' ');
        });
        // handlebars.registerHelper('configuration', function (key) {
        //     return configuration[key] || 'configuration - unknown key:' + key;
        // });
        handlebars.registerHelper('debug', function () {
            if (arguments.length > 1) {
                console.log(_.slice(arguments, 0, arguments.length - 1));
            }
            console.log(this);
        });
        handlebars.registerHelper('dateString', function (date) {
            let d;
            if (_.isString(date) && date !== '0000-00-00 00:00:00') { // mysql's undefined datetime
                d = new Date(date);
            }
            return _.isUndefined(d) || _.isNaN(d.getTime()) ? '-' : d.toISOString().slice(0, 10);  // 2019-02-01
        });
        handlebars.registerHelper('dateFormat', function (date, format) {
            const d = moment(date);
            if (d.isValid()) {
                return d.format(format);
            } else {
                return date;
            }
        });
        handlebars.registerHelper('ifArrayIncludes', function () {
            const values = _.slice(arguments, 0, arguments.length - 2),
                array = arguments[arguments.length - 2],
                options = arguments[arguments.length - 1];
            return _.intersection(array, values).length ? options.fn(this) : options.inverse(this);
        });
        handlebars.registerHelper('ifListIncludes', function () {
            const a = _.values(arguments),
                value = a[0] || '',
                list = a.slice(1, a.length - 1),
                options = a.slice(a.length - 1)[0];
            return list.includes(value.split('/')[0]) ? options.fn(this) : options.inverse(this); // re: .split('/')[0] - e.g. 'assessments/first' -> 'assessments'
        });
        handlebars.registerHelper('toLowerCase', function (s) {
            return s.toLowerCase();
        });
        handlebars.registerHelper('toSentenceCase', function (s) {
            const a = s.split(' ');
            a[0] = _.startCase(a[0]);
            return a.join(' ');
        });
        handlebars.registerHelper('toTitleCase', function (s) {
            return _.startCase(s);
        });
        handlebars.registerHelper('toUpperCase', function (s) {
            return s.toUpperCase();
        });
        handlebars.registerHelper('truncate', function (n) {
            return Math.floor(n);
        });
        handlebars.registerHelper('urlEncode', function (s) {
            return encodeURIComponent(s);
        });
        handlebars.registerHelper('year', function () {
            return (new Date()).getFullYear();
        });
        handlebars.registerHelper('enumName', function (enumName, enumValue) {
            const enumElem = enumerations.get(enumName + 'ByNameLookup')[enumValue] || {};
            const name = enumElem.name || '';
            return _.startCase(name);
        });
        handlebars.registerHelper('exactMonthSelect', function (questionId, responseId, answer) {
            console.log(questionId, responseId, answer);
            const months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
            const select = [];

            select.push('<select class="custom-select">');
            select.push('<option value="0"></option>');
            for (let month = 0; month < months.length; month++) {
                select.push(`<option value="${month+1}" data-question-id="${questionId}" data-single-response="1" data-response-id="${responseId}" ${String(month+1) === String(answer) ? 'selected' : ''}>${months[month]}</option>`);
            }
            select.push('</select>');

            return select.join('\n');
        });
        handlebars.registerHelper('exactYearSelect', function (questionId, responseId, answer) {
            const select = [];
            const currentYear = moment().year();

            select.push('<select class="custom-select">');
            select.push('<option value="0"></option>');
            for (let year=1980; year <= currentYear; year++) {
                select.push(`<option value="${year}" data-question-id="${questionId}" data-single-response="1" data-response-id="${responseId}" ${String(year) === String(answer) ? 'selected' : ''}>${year}</option>`);
            }
            select.push('</select>');

            return select.join('\n');
        });
        handlebars.registerHelper('enumSelect', function (opts) {
            const hash = opts.hash;
            const options = [],
                field = hash.field || 'name',
                titleClass = hash.titleClass || 'edit-item-title',
                label = hash.label ? '<label for="' + hash.label + '" class="' + titleClass + '">' + hash.label + ':</label>\n' : '',
                id = hash.label ? hash.label : '';
            // initial option representing a null value. Note that we cannot use these controls to UNSET a set value, just to represent an unset value.
            if (!hash.selected) {
                options.push({
                    option: hash.topOption || '',
                    order: -1,
                    selected: true,
                    value: '-1' // sentinal value of -1 will be dropped by the form processor...
                });
            }
            if (hash.selected === -1) {
                // -1 indicates that the first options should be selected...
                hash.selected = _.keys(enumerations[hash.property])[0];
            }
            _.each(enumerations.get(hash.property), function (tip, tipId) {
                tipId = +tipId;
                options.push({
                    option: _.startCase(tip[field]),
                    order: tip.orderNumber,
                    selected: tipId == hash.selected,
                    value: tipId
                });
            });
            options.sort(function (a, b) {
                return a.order - b.order;
            });
            return label + templates.select({
                disabled: hash.disabled,
                id: id,
                name: hash.name || hash.property,
                options: options,
                selectedValue: hash.selected
            });
        });
        // {{#ifCond var1 '==' var2}}
        handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
            switch (operator) {
            case '==':
                return (v1 == v2) ? options.fn(this) : options.inverse(this);
            case '===':
                return (v1 === v2) ? options.fn(this) : options.inverse(this);
            case '!=':
                return (v1 != v2) ? options.fn(this) : options.inverse(this);
            case '!==':
                return (v1 !== v2) ? options.fn(this) : options.inverse(this);
            case '<':
                return (v1 < v2) ? options.fn(this) : options.inverse(this);
            case '<=':
                return (v1 <= v2) ? options.fn(this) : options.inverse(this);
            case '>':
                return (v1 > v2) ? options.fn(this) : options.inverse(this);
            case '>=':
                return (v1 >= v2) ? options.fn(this) : options.inverse(this);
            case '&&':
                return (v1 && v2) ? options.fn(this) : options.inverse(this);
            case '||':
                return (v1 || v2) ? options.fn(this) : options.inverse(this);
            case 'includes':
                return (v1 || '').includes(v2) ? options.fn(this) : options.inverse(this);
            case 'startsWith':
                return (v1 || '').startsWith(v2) ? options.fn(this) : options.inverse(this);
            default:
                return options.inverse(this);
            }
        });
        const reduceOp = function (args, reducer){
            args = Array.from(args);
            args.pop(); // => options
            const first = args.shift();
            return args.reduce(reducer, first);
        };

        handlebars.registerHelper({
            eq  : function (){ return reduceOp(arguments, (a,b) => a === b); },
            ne  : function (){ return reduceOp(arguments, (a,b) => a !== b); },
            lt  : function (){ return reduceOp(arguments, (a,b) => a  <  b); },
            gt  : function (){ return reduceOp(arguments, (a,b) => a  >  b); },
            lte : function (){ return reduceOp(arguments, (a,b) => a  <= b); },
            gte : function (){ return reduceOp(arguments, (a,b) => a  >= b); },
            and : function (){ return reduceOp(arguments, (a,b) => a  && b); },
            or  : function (){ return reduceOp(arguments, (a,b) => a  || b); }
        });
        if (callback) {
            callback(null);
        }
    },
    // registerHelper: function (name, helper) {
    //     handlebars.registerHelper(name, helper);
    // },
    // registerTemplatePartials: function () {
    //     handlebars.registerPartial('applicantRow', )
    // },
    helpers: helpers,
    templates: handlebars.templates
};
