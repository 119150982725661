'use strict';

const Marionette = require('backbone.marionette'),
    SiteStatus = require('../../../collections/siteStatus'),
    //
    User = require('../../../singletons/user'),
    //
    TimelineView = require('../../timeline/timeline'),
    //
    templates = require('../../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'site-timelines',
        'data-name': 'site-timelines'
    },
    tagName: 'div',
    getTemplate: function () {
        return templates[this.templateName][User.getRole()];
    },
    template: templates.siteStatusDashboard,
    initialize: function (options) {
        this.templateName = options.templateName || 'siteStatusDashboard';
        this.collection.fetch()
            .then(() => this.render());
    },
    onRender: function () {
        this.collection.each((model) => {
            const programId = model.get('programId');
            const organizationId = model.get('organizationId');
            const siteTimelineName = `site-timeline-${programId}-${organizationId}`;
            const siteStatus = new SiteStatus(null, { programId: programId, siteId: organizationId });

            this.addRegion(siteTimelineName, `div[data-region=${siteTimelineName}]`);
            this.showChildView(siteTimelineName, new TimelineView({
                collection: siteStatus
            }));
        });
    }
});
