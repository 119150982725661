'use strict';

const Backbone = require('backbone'),
    Program = require('../models/program');

module.exports = Backbone.Collection.extend({
    model: Program,
    //
    url: '/programs',
    //
    forDashboard: false,
    offset: 0,
    count: 35,
    sortColumns: 'name',
    sortDirection: 'ASC',
    //
    initialize: function () {
        this.on('reset', () => {
            this.offset = 0;
        });
    },
    sync: function (method, model, options) {
        options = options || {};
        options.data = {
            programStatusId: this.programStatusId,
            matchingText: this.matchingText,
            forDashboard: this.forDashboard,
            sortColumns: this.sortColumns,
            sortDirection: this.sortDirection,
            offset: this.offset,
            count: this.count
        };
        return Backbone.sync.apply(this, [method, model, options]);
    },
    parse: function (results) {
        this.offset += results && results.length || 0;
        return results;
    },
    getForDashboard: function () {
        return this.forDashboard;
    },
    setForDashboard: function (forDashboard) {
        this.forDashboard = forDashboard;
    },
    getOffset: function () {
        return this.offset;
    },
    setOffset: function (offset) {
        this.offset = offset;
    },
    getCount: function () {
        return this.count;
    },
    setCount: function (count) {
        this.count = count;
    },
    getProgramStatusId: function () {
        return this.programStatusId;
    },
    setProgramStatusId: function (programStatusId) {
        this.programStatusId = programStatusId;
    },
    getMatchingText: function () {
        return this.matchingText;
    },
    setMatchingText: function (matchingText) {
        this.matchingText = matchingText;
    },
    getSortColumns: function () {
        return this.sortColumns;
    },
    setSortColumns: function (sortColumns) {
        this.sortColumns = sortColumns;
    },
    getSortDirection: function () {
        return this.sortDirection;
    },
    setSortDirection: function (sortDirection) {
        this.sortDirection = sortDirection;
    }
});
