'use strict';

const Marionette = require('backbone.marionette'),
    // $ = require('jquery'),
    //
    Router = require('../../singletons/router'),
    //
    templates = require('../../utilities/handlebars').templates;

module.exports = Marionette.View.extend({
    attributes: {
        class: 'sidebar',
        'data-name': 'sidebar'
    },
    tagName: 'div',
    template: templates.sidebar,
    //
    initialize: function () {
        this.listenTo(Router, 'route', this.onNewRoute);
    },
    onAttach: function () {
        this.$el.parent().addClass('sidebar-container');
        // In case the route change came in before we were rendered, so no matching HTML element was found.
        this.onNewRoute(this.route);
    },
    onNewRoute: function (route) {
        this.route = route;
        const el = this.$(`.nav-${route}`);
        this.$el.find('a').removeClass('active');
        el.addClass('active');
    }
});
