'use strict';

const Backbone = require('backbone'),
    Marionette = require('backbone.marionette'),
    _ = require('lodash'),
    //
    templates = require('../../utilities/handlebars').templates;

const StatesCounties = Backbone.Collection.extend({
    url: '/states/counties',
    setStates: function (states) {
        this.states = states;
    },
    sync: function (method, model, options) {
        options = options || {};
        options.data = {
            states: this.states
        };
        return Backbone.sync.apply(this, [method, model, options]);
    }
});

const StatesZipcodes = Backbone.Collection.extend({
    url: '/states/zipcodes',
    setStates: function (states) {
        this.states = states;
    },
    sync: function (method, model, options) {
        options = options || {};
        options.data = {
            states: this.states
        };
        return Backbone.sync.apply(this, [method, model, options]);
    }
});

module.exports = Marionette.View.extend({
    attributes: {
        class: 'zipcode',
        'data-name': 'zipcode'
    },
    ui: {
        nationalState: 'input[name=q1861]',
        stateList: '#bpccq3br1',
        stateCountyZipcode: 'input[name=q1863]',
        countyList: '#bpccq3dr1',
        zipcodeList: '#bpccq3er1',
        stateMultiselect: '#multiselect-states',
        countyMultiselect: '#multiselect-counties',
        zipcodeMultiselect: '#multiselect-zipcodes'
    },
    events: {
        'change @ui.nationalState': 'onQ3aChanged',
        'change @ui.stateMultiselect': 'onQ3bChanged',
        'change @ui.stateCountyZipcode': 'onQ3cChanged',
        'change @ui.countyMultiselect': 'onQ3dChanged',
        'change @ui.zipcodeMultiselect': 'onQ3eChanged'
    },
    tagName: 'div',
    template: templates.zipcode,
    states: [
        { value: '01', text: 'Alabama', selected: false },
        { value: '02', text: 'Alaska', selected: false },
        { value: '04', text: 'Arizona', selected: false },
        { value: '05', text: 'Arkansas', selected: false },
        { value: '06', text: 'California', selected: false },
        { value: '08', text: 'Colorado', selected: false },
        { value: '09', text: 'Connecticut', selected: false },
        { value: '10', text: 'Delaware', selected: false },
        { value: '11', text: 'Dstrict of Columbia', selected: false },
        { value: '12', text: 'Florida', selected: false },
        { value: '13', text: 'Georgia', selected: false },
        { value: '15', text: 'Hawaii', selected: false },
        { value: '16', text: 'Idaho', selected: false },
        { value: '17', text: 'Illinois', selected: false },
        { value: '18', text: 'Indiana', selected: false },
        { value: '19', text: 'Iowa', selected: false },
        { value: '20', text: 'Kansas', selected: false },
        { value: '21', text: 'Kentucky', selected: false },
        { value: '22', text: 'Louisiana', selected: false },
        { value: '23', text: 'Maine', selected: false },
        { value: '24', text: 'Maryland', selected: false },
        { value: '25', text: 'Massachusetts', selected: false },
        { value: '26', text: 'Michigan', selected: false },
        { value: '27', text: 'Minnesota', selected: false },
        { value: '28', text: 'Mississippi', selected: false },
        { value: '29', text: 'Missouri', selected: false },
        { value: '30', text: 'Montana', selected: false },
        { value: '31', text: 'Nebraska', selected: false },
        { value: '32', text: 'Nevada', selected: false },
        { value: '33', text: 'New Hampshire', selected: false },
        { value: '34', text: 'New Jersey', selected: false },
        { value: '35', text: 'New Mexico', selected: false },
        { value: '36', text: 'New York', selected: false },
        { value: '37', text: 'North Carolina', selected: false },
        { value: '38', text: 'North Dakota', selected: false },
        { value: '39', text: 'Ohio', selected: false },
        { value: '40', text: 'Oklahoma', selected: false },
        { value: '41', text: 'Oregon', selected: false },
        { value: '42', text: 'Pennsylvania', selected: false },
        { value: '44', text: 'Rhode Island', selected: false },
        { value: '45', text: 'South Carolina', selected: false },
        { value: '46', text: 'South Dakota', selected: false },
        { value: '47', text: 'Tennessee', selected: false },
        { value: '48', text: 'Texas', selected: false },
        { value: '49', text: 'Utah', selected: false },
        { value: '50', text: 'Vermont', selected: false },
        { value: '51', text: 'Virginia', selected: false },
        { value: '53', text: 'Washington', selected: false },
        { value: '54', text: 'West Virginia', selected: false },
        { value: '55', text: 'Wisconsin', selected: false },
        { value: '56', text: 'Wyoming', selected: false }
    ],
    initialize: function () {
        this.stateCounties = new StatesCounties();
        this.stateZipcodes = new StatesZipcodes();
        this.selectedStates = this.findListByResponseCode('bpccq3br1');
        this.selectedCounties = this.findListByResponseCode('bpccq3dr1');
        this.selectedZipcodes = this.findListByResponseCode('bpccq3er1');

        this.states.forEach((s) => s.selected = this.selectedStates.includes(s.value));
        this.model.set('states', this.states);

        if (this.selectedStates.length) {
            this.stateCounties.setStates(this.selectedStates);
            this.stateZipcodes.setStates(this.selectedStates);
            Promise.all([this.stateCounties.fetch(), this.stateZipcodes.fetch()]).then(() => this.refillMultiSelects());
        } else {
            this.resetMultiselects();
        }
    },
    onQ3aChanged: function (event) {
        const id = event.currentTarget.id;
        this.setNationalState(id);
    },
    onQ3bChanged: function () {
        this.selectedStates = this.ui.stateMultiselect.val();
        this.ui.stateList.val(this.selectedStates);
        this.stateCounties.setStates(this.selectedStates);
        this.stateZipcodes.setStates(this.selectedStates);
        Promise.all([this.stateCounties.fetch(), this.stateZipcodes.fetch()]).then(() => this.refillMultiSelects());
    },
    onQ3cChanged: function (event) {
        const id = event.currentTarget.id;
        this.setStateCountyZipcode(id);
    },
    setNationalState: function (id) {
        switch (id) {
        case 'bpccq3ar10':
            this.ui.stateMultiselect.attr('disabled', 'true');
            this.ui.stateCountyZipcode.attr('disabled', 'true');
            this.ui.countyMultiselect.attr('disabled', 'true');
            this.ui.zipcodeMultiselect.attr('disabled', 'true');
            break;
        case 'bpccq3ar11':
            this.ui.stateMultiselect.removeAttr('disabled');
            this.ui.stateCountyZipcode.removeAttr('disabled');
            this.setStateCountyZipcode(this.ui.stateCountyZipcode.filter(':checked').attr('id'));
            break;
        }
    },
    setStateCountyZipcode: function (id) {
        switch (id) {
        case 'bpccq3cr10':
            this.ui.countyMultiselect.attr('disabled', 'true');
            this.ui.zipcodeMultiselect.attr('disabled', 'true');
            break;
        case 'bpccq3cr11':
            this.ui.countyMultiselect.removeAttr('disabled');
            this.ui.zipcodeMultiselect.attr('disabled', 'true');
            break;
        case 'bpccq3cr12':
            this.ui.countyMultiselect.attr('disabled', 'true');
            this.ui.zipcodeMultiselect.removeAttr('disabled');
        }
    },
    onQ3dChanged: function () {
        this.selectedCounties = this.ui.countyMultiselect.val();
        this.ui.countyList.val(this.selectedCounties);
    },
    onQ3eChanged: function () {
        this.selectedZipcodes = this.ui.zipcodeMultiselect.val();
        this.ui.zipcodeList.val(this.selectedZipcodes);
    },
    findListByResponseCode: function (responseCode) {
        const responses = this.model.get('responses'),
            responseCodeResponses = _.find(responses, { responseCode }),
            responseList = (responseCodeResponses && responseCodeResponses.answers && responseCodeResponses.answers[0] && responseCodeResponses.answers[0].responseValue) || '',
            selectedList = (responseList.length) ? responseList.split(',') : [];

        return selectedList;
    },
    refillMultiSelects: function () {
        this.resetMultiselects();
        if (!this.stateCounties.length) {
            this.stateCounties.add({ countyFipsCode: 'none', name: 'Select Counties' });
        }
        if (!this.stateZipcodes.length) {
            this.stateZipcodes.add({ zipcode: 'Select Zipcodes' });
        }
        this.ui.countyMultiselect.empty().html(this.stateCounties.map((c) => `<option value="${c.get('countyFipsCode')}" ${c.get('selected')}>${c.get('name')}</option>`));
        this.ui.zipcodeMultiselect.empty().html(this.stateZipcodes.map((z) => `<option value="${z.get('zipcode')}" ${z.get('selected')}>${z.get('zipcode')}</option>`));
    },
    resetMultiselects: function () {
        this.stateCounties.each((c) => c.set('selected', (this.selectedCounties.includes(c.get('countyFipsCode'))) ? 'selected' : ''));
        this.model.set('counties', this.stateCounties);

        this.stateZipcodes.each((z) => z.set('selected', (this.selectedZipcodes.includes(z.get('zipcode'))) ? 'selected' : ''));
        this.model.set('zipcodes', this.stateZipcodes);
    },
    onRender: function () {
        const nationalStateSelectionId = this.ui.nationalState.filter(':checked').attr('id'),
            stateCountyZipcodeSelectionId = this.ui.stateCountyZipcode.filter(':checked').attr('id');

        // Make sure all the appropriate controls are enabled or disabled.
        this.setNationalState(nationalStateSelectionId);
        this.setStateCountyZipcode(stateCountyZipcodeSelectionId);
    }
});
