'use strict';

const Sites = require('./sites');
module.exports = Sites.extend({
    //
    model: null,
    //
    url: null,      // Abstract collection
    //
    userId: 0,
    //
    initialize: function (options) {
        this.userId = options.userId;
        this.on('reset', () => {
            this.offset = 0;
        });
    },
    getUserId: function () {
        return this.userId;
    },
    setUserId: function (developerId) {
        this.userId = developerId;
    }
});
