'use strict';

const Developers = require('./developers');

module.exports = Developers.extend({
    //
    url: function () {
        return '/developers/user/' + this.userId;
    },
    //
    userId: 0,
    //
    initialize: function (options) {
        this.userId = options.userId;
        this.on('reset', () => {
            this.offset = 0;
        });
    },
    getUserId: function () {
        return this.userId;
    },
    setUserId: function (developerId) {
        this.userId = developerId;
    }
});
