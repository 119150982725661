'use strict';

const Marionette = require('backbone.marionette'),
    Radio = require('backbone.radio'),
    autocomplete = require('autocompleter'),
    $ = require('jquery'),
    _ = require('lodash'),
    //
    DeveloperPrograms = require('../../collections/developerPrograms'),
    DeveloperUsers = require('../../collections/developerUsers'),
    Developer = require('../../models/developer'),
    User = require('../../models/user'),
    Users = require('../../collections/users'),
    //
    OrganizationUsersView = require('../organizationUsers/organizationUsers'),
    ProgramsList = require('../programs/programsList/programsList'),
    UserView = require('../user/user'),
    //
    templates = require('../../utilities/handlebars').templates;

const DeveloperUserModel = User.extend({
    idAttribute: 'userId',
    urlRoot: function () {
        return `/developer/user/${this.get('developerId')}`;
    },
    setUserId: function (userId) {
        this.set('userId', userId);
    }
});

module.exports = Marionette.View.extend({
    attributes: {
        class: 'developer',
        'data-name': 'developer'
    },
    ui: {
        createUser: '.js-create-user',
        developerName: '.js-developer-name',
        form: 'form',
        saveEdit: '.js-save-edit-button',
        delete: '.js-delete-button',
        cancel: '.js-cancel-button',
        submit: '.js-submit-button',
        //
        programsCount: '.js-programs-count',
        adminEmail: '[name="email"]',
        adminUserId: '[name="adminUserId"]',
        adminUserName: '[name="fullName"]'
    },
    events: {
        'click @ui.createUser': 'onCreateUser',
        'click @ui.saveEdit': 'onSubmit',
        'click @ui.delete': 'onDelete',
        'click @ui.cancel': 'onCancel',
        'click @ui.submit': 'onSubmit'
    },
    childViewEvents: {
        'refresh.programs': 'refreshPrograms'
    },
    childViewTriggers: {
        'modal:show': 'modal:show',
        'modal:hide': 'modal:hide'
    },
    regions: {
        developerPrograms: {el: 'div[data-region=developer-programs-list]', replaceElement: true},
        developerUsers: {el: 'div[data-region=developer-users]', replaceElement: true}
    },
    tagName: 'div',
    getTemplate: function () {
        return (this.isModal) ? templates.developerModal : templates.developer;
    },
    //
    initialize: function (developerId, isModal) {
        // We can enter here from two possible routes: '/developer' and '/developer/:developerId'
        // '/developer' is the easy case. We are creating a new developer and all the form fields can be blank.
        // '/developer/:developerId' means we are editing an existing developer. We fetch the data for this developer and fill in the form.
        if (developerId) {
            this.$el.addClass('is-existing');
            this.model = new Developer({ id: developerId });
            this.model.fetch()
                .then(() => this.render())
                // All fields that are *required* in the PUT really ought to be returned by the GET.
                .then(() => this.setFieldsFromModel());
            this.collections = {
                programs: new DeveloperPrograms({ developerId: developerId }),
                users: new DeveloperUsers({ developerId: developerId })
            };

            this.listenTo(this.collections.programs, 'sync', () => {
                this.ui.programsCount.text(this.collections.programs.length);
            });
        } else {
            this.$el.addClass('is-new');
            this.model = new Developer();
        }
        this.isModal = isModal;
    },
    setFieldsFromModel: function () {
        this.ui.form.find(':input').each((ix, elem) => this.$(elem).val(this.model.get((this.$(elem).attr('name')))));
    },
    onRender: function () {
        autocomplete({
            input: this.ui.adminUserName[0],
            className: 'list-group scroll-y',
            emptyMsg: 'No matching Users found',
            minLength: 1,
            showOnFocus: true,
            debounceWaitMs: 200,
            fetch: function (text, update) {
                const users = new Users();
                users.setMatchingText(text);
                users.fetch()
                    .then((results) => {
                        results.forEach((user) => user.label = user.fullName);
                        update(results);
                    });
            },
            render: (item) => {
                return $(templates.organizationAddUserRow(item))[0];
            },
            onSelect: (item) => {
                this.ui.adminUserName.val(item.label);
                this.ui.adminEmail.text(item.email);
                this.ui.adminUserId.val(item.id);
            }
        });

        if (this.model.id) {
            this.collections.programs.reset();
            this.programsList = this.showChildView('developerPrograms', new ProgramsList({
                collection: this.collections.programs,
                rowTemplate: templates.developerProgramsListRow
            }));

            this.collections.users.reset();
            this.users = this.showChildView('developerUsers', new OrganizationUsersView({
                organizationUserModel: new DeveloperUserModel({ developerId: this.model.id }),
                collection: this.collections.users
            }));
        }

        const developerName = (this.model.id) ? this.model.get('name') : 'Developer';
        this.ui.developerName.text(developerName);
    },
    onDelete: function () {
        this.model.destroy()
            .always(() => this.trigger('navigate', 'developers'));

        return false;
    },
    onCancel: function () {
        if (this.isModal) {
            this.trigger('modal:hide');
        } else {
            this.trigger('navigate', 'developers');
        }
        return false;
    },
    onSubmit: function () {
        const data = _.mapValues(_.keyBy(this.ui.form.serializeArray(), 'name'), 'value');
        this.model.save(data)
            .then(() => {
                if (this.isModal) {
                    const channel = Radio.channel('developer');
                    channel.request('developer', this.model.id, this.model.getName());
                    this.trigger('modal:hide');
                } else {
                    if (this.$el.hasClass('is-existing')) {
                        this.refreshUsers();
                    } else {
                        this.$el.removeClass('is-new').addClass('is-existing');
                        this.trigger('navigate', `site/${this.model.id}`);
                    }
                }
            });

        return false;
    },
    onCreateUser: function () {
        const channel = Radio.channel('user');
        channel.reply('user', (userId, fullName, email) => {
            // console.log('new user', userId);

            this.ui.adminUserName.val(fullName);
            this.ui.adminEmail.text(email);
            this.ui.adminUserId.val(userId);

            const developerUserModel = new DeveloperUserModel({
                developerId: this.model.id,
                userId
            });
            developerUserModel.save()
                .then(() => this.refreshUsers());
        });
        this.trigger('modal:show', new UserView(0, true));
        return false;
    },
    refreshPrograms: function () {
        this.collections.programs.reset();
        this.collections.programs.fetch();
    },
    refreshUsers: function () {
        this.collections.users.reset();
        this.collections.users.fetch();
    }
});
