'use strict';

const Users = require('./users');

module.exports = Users.extend({
    //
    url: function () {
        return '/users/developer/' + this.developerId;
    },
    //
    developerId: 0,
    //
    initialize: function (options) {
        this.developerId = options.developerId;
        this.on('reset', () => {
            this.offset = 0;
        });
    },
    getDeveloperId: function () {
        return this.developerId;
    },
    setDeveloperId: function (developerId) {
        this.developerId = developerId;
    }
});
