'use strict';

const Sites = require('./sites'),
    DeliverySite = require('../models/deliverySite');

const DashboardSites = Sites.extend({
    //
    model: DeliverySite,
    //
    url: '/sites/dashboard'
});

module.exports = DashboardSites;